import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateUserRequest,
  deleteUserRequest,
  logout
} from '../../actions/user';
import { getActiveUserSelector } from '../../selectors/user';
import Button from '../../components/inputs/Button';
import ChangePasswordModal from '../../components/Modal/changePasswordModal';
import DashboardHeader from '../../components/DashboardHeader';
import DeleteUserModal from '../../components/Modal/deleteUserModal';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

class UserSettings extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    password: '',
    confirmPassword: '',
    openPasswordModal: false,
    openDeleteUserModal: false
  };

  componentWillMount() {
    if (this.props.activeUser) {
      this.setState({ ...this.props.activeUser });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      company,
      password,
      confirmPassword,
      openPasswordModal,
      openDeleteUserModal
    } = this.state;

    const { updateUser, deleteUser, logout } = this.props;

    return (
      <>
        <div className={styles.settings}>
          <DashboardHeader logout={logout} />
          <div className={styles.settingsBody}>
            <h2>User Settings</h2>
            <Textbox
              label="First Name"
              fullWidth
              currentValue={firstName}
              handleChange={(values) => this.setState({ firstName: values })}
            />
            <Textbox
              label="Last Name"
              fullWidth
              currentValue={lastName}
              handleChange={(values) => this.setState({ lastName: values })}
            />
            <Textbox
              label="Company Name"
              fullWidth
              currentValue={company}
              handleChange={(values) => this.setState({ company: values })}
            />
            <Textbox
              label="Email"
              fullWidth
              currentValue={email}
              handleChange={(values) => this.setState({ email: values })}
            />
            <div className={styles.buttonRow}>
              <Button
                color="orange"
                text="Update User"
                handleClick={() => updateUser(this.state)}
              />
              <Button
                color="white"
                text="Change Password"
                handleClick={() => this.setState({ openPasswordModal: true })}
              />
              <Button
                color="red"
                text="Delete User"
                handleClick={() => this.setState({ openDeleteUserModal: true })}
              />
            </div>
          </div>
        </div>
        <DeleteUserModal
          isOpen={openDeleteUserModal}
          username={`${firstName}  ${lastName}`}
          submitActions={() => deleteUser(this.state)}
          closeActions={() => this.setState({ openDeleteUserModal: false })}
        />
        <ChangePasswordModal
          isOpen={openPasswordModal}
          password={password}
          confirmPassword={confirmPassword}
          // submitActions={() => deleteUser(this.state)}
          updatePassword={(values) => this.setState(values)}
          closeActions={() => this.setState({ openPasswordModal: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeUser: getActiveUserSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(updateUserRequest(payload)),
  deleteUser: (payload) => dispatch(deleteUserRequest(payload)),
  logout: (payload) => dispatch(logout(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
