/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import ReactTooltip from 'react-tooltip';
import { BUYER_POWER, FOE_FRIEND } from '../../constants/constants';
import {
  influenceMapper,
  foeFriendMapper,
  buyerPowerMapper,
  roleMapper,
  typeMapper
} from '../../utils/userCardHelper';
import Button from '../inputs/Button';
import Switch from '../inputs/Switch';
import Textarea from '../inputs/Textarea';
import UserCardNav from '../UserCardNav';

import styles from './styles.module.scss';

export default class UserCard extends Component {
  state = {
    roles: [],
    types: [],
    controlledPosition: {
      x: 0,
      y: 0
    },
    displayNotes: false,
    buyerPowerOption: {},
    friendFoeOption: {}
  };

  componentDidMount() {
    const buyerPowerOption = BUYER_POWER.find(
      (bp) => bp.value === parseInt(this.props.userCard.buyerPower, 10)
    ) || {
      tooltipName: 'risk',
      tooltip:
        'This is a risk.  Take action to determine their level of influence.'
    };

    const friendFoeOption = FOE_FRIEND.find(
      (ff) => ff.value === parseInt(this.props.userCard.friendFoe, 10)
    ) || {
      tooltipName: 'noSupport',
      tooltip: 'Take action to determine their support of you'
    };

    this.setState({
      ...this.props.userCard,
      friendFoeOption,
      buyerPowerOption
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userCard &&
      JSON.stringify(prevProps.userCard) !== JSON.stringify(this.props.userCard)
    ) {
      const buyerPowerOption = BUYER_POWER.find(
        (bp) => bp.value === parseInt(this.props.userCard.buyerPower, 10)
      ) || {
        tooltipName: 'risk',
        tooltip:
          'This is a risk.  Take action to determine their level of influence.'
      };

      const friendFoeOption = FOE_FRIEND.find(
        (ff) => ff.value === parseInt(this.props.userCard.friendFoe, 10)
      ) || {
        tooltipName: 'noSupport',
        tooltip: 'Take action to determine their support of you'
      };

      this.setState({
        ...this.props.userCard,
        friendFoeOption,
        buyerPowerOption
      });
    }
  }

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
    this.props.updateUserCardPosition(this.state);
  };

  updateUserCard = () => {
    this.props.updateUserCard({
      ...this.state,
      keyBuyer: !this.state.keyBuyer
    });
    this.setState({ keyBuyer: !this.state.keyBuyer });
  };

  render() {
    const {
      name,
      jobTitle,
      image,
      roles,
      types,
      keyBuyer,
      friendFoe,
      buyerPower,
      notes,
      displayNotes,
      controlledPosition,
      _id,
      buyerPowerOption,
      friendFoeOption
    } = this.state;

    const {
      updateUser,
      handleDeleteUser,
      duplicateCard,
      disabled,
      index,
      openLinkModal,
      openUnlinkModal
    } = this.props;

    const influence = influenceMapper(buyerPower, friendFoe);

    const dragHandlers = {
      position: controlledPosition,
      bounds: 'parent',
      onDrag: this.onControlledDrag
    };

    return (
      <Rnd {...dragHandlers} className={`${styles.card} userCard_${_id}`}>
        <div className={styles.userCard}>
          <header>
            <div className={styles.userName}>{name}</div>
            <div className={styles.userJobTitle}>{jobTitle}</div>
          </header>
          <UserCardNav
            updateUser={() => updateUser(this.state)}
            deleteUser={handleDeleteUser}
            duplicateCard={duplicateCard}
            openLinkingModal={openLinkModal}
            openUnlinkingModal={openUnlinkModal}
            disabled={disabled}
          />
          <div className={styles.imageContainer}>
            {image && <img src={image} alt="User Pic" />}
            <div className={styles.influenceContainer}>
              <div>Influence Indictator</div>
              <div className={styles[influence.color]}>{influence.text}</div>
            </div>
          </div>
          <div className={styles.userInfo}>
            <div>
              <div>Notes</div>
              <Button
                color="gray-gradient"
                showImage
                image={require('../../assets/images/openNotes.png')}
                handleClick={() => this.setState({ displayNotes: true })}
                userCards
              />
            </div>
            <div>
              <div>Key Buyer</div>
              <Switch
                isOn={keyBuyer}
                handleToggle={() => this.updateUserCard()}
                index={index}
              />
            </div>
            <div>
              <div>Role</div>
              <div>
                {roles.map((role, index) => (
                  <div key={index}>
                    <img
                      src={roleMapper(role).img}
                      alt="type"
                      data-tip={`role_${role}`}
                      data-for={`role_${role}`}
                    />
                    <ReactTooltip
                      className={styles.customTheme}
                      place="bottom"
                      effect="solid"
                      type="light"
                      id={`role_${role}`}
                    >
                      <div>
                        <h2>{roleMapper(role).header}</h2>
                        {roleMapper(role).text}
                      </div>
                    </ReactTooltip>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div>Type</div>
              <div>
                {types.map((type, index) => (
                  <div key={index}>
                    <img
                      src={typeMapper(type).img}
                      alt="type"
                      data-tip={`type_${type}`}
                      data-for={`type_${type}`}
                    />
                    <ReactTooltip
                      className={styles.customTheme}
                      place="bottom"
                      effect="solid"
                      type="light"
                      id={`type_${type}`}
                    >
                      <div>
                        <h2>{typeMapper(type).header}</h2>
                        {typeMapper(type).text}
                      </div>
                    </ReactTooltip>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div
              className={styles[foeFriendMapper(parseInt(friendFoe, 10))]}
              data-tip={`${friendFoeOption.tooltipName}_${_id}`}
              data-for={`${friendFoeOption.tooltipName}_${_id}`}
            >
              <div className={styles.infoText}>Foe/ Friend</div>
              <div className={styles.number}>
                {!isNaN(parseInt(friendFoe, 10)) ? friendFoe : '?'}
              </div>
            </div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id={`${friendFoeOption.tooltipName}_${_id}`}
              key={`tooltipKey${friendFoeOption.tooltipName}`}
            >
              <div>{friendFoeOption.tooltip}</div>
            </ReactTooltip>
            <div
              className={styles[buyerPowerMapper(parseInt(buyerPower, 10))]}
              data-tip={`${buyerPowerOption.tooltipName}_${_id}`}
              data-for={`${buyerPowerOption.tooltipName}_${_id}`}
            >
              <div className={styles.infoText}>Buyer Power</div>
              <div className={styles.number}>
                {parseInt(buyerPower, 10) > -1 ? buyerPower : '?'}
              </div>
            </div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id={`${buyerPowerOption.tooltipName}_${_id}`}
              key={`tooltipKey${buyerPowerOption.tooltipName}`}
            >
              <div>{buyerPowerOption.tooltip}</div>
            </ReactTooltip>
          </div>
        </div>
        <div
          className={
            displayNotes
              ? [styles.notesSection, styles.showNotes].join(' ')
              : styles.notesSection
          }
        >
          <header>
            NOTES
            <Button
              color="gray-gradient"
              showImage
              image={require('../../assets/images/closeNotes.png')}
              handleClick={() => this.setState({ displayNotes: false })}
              userCards
            />
          </header>
          <div>
            <Textarea
              label="Strategy"
              placeholder="Notes about strategy goes in this input box."
              notesSection
              currentValue={notes?.strategy}
              disabled={true}
            />
            <Textarea
              label="Comments"
              placeholder="Any additional comments about the individual goes here."
              notesSection
              currentValue={notes?.comment}
              disabled={true}
            />
          </div>
        </div>
      </Rnd>
    );
  }
}
