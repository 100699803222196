import React, { Component } from 'react';

import styles from './styles.module.scss';

export default class Dropdown extends Component {
  state = {
    showOptions: false,
    currentText: '',
    currentValue: ''
  };

  componentDidMount() {
    if (this.props?.currentValue?.label) {
      this.setState({
        currentText: this.props.currentValue.label,
        currentValue: this.props.currentValue.value
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentValue &&
      this.props.currentValue !== prevProps.currentValue
    ) {
      this.setState({
        currentText: this.props.currentValue.label,
        currentValue: this.props.currentValue.value
      });
    }
  }

  handleSelectionChange(selection) {
    this.props.handleChange(selection);
    if (selection.value === null) {
      this.setState({
        showOptions: false
      });
      return;
    } else {
      this.setState({
        currentValue: selection.value,
        currentText: selection.label,
        showOptions: false
      });
    }
  }

  _onBlur = () => {
    this.setState({ showOptions: false });
  };

  render() {
    const { options, label, placeholder, hideMaps } = this.props;
    const { showOptions, currentText } = this.state;

    return (
      <>
        <div className={styles.dropdown}>
          {label && <label htmlFor="textbox">{label}</label>}
          <ul
            className={showOptions ? styles.dropdownStraightBorder : ''}
            tabIndex="-1"
          >
            <li
              className={showOptions ? styles.dropdownOpen : ''}
              onMouseDown={() => {
                this.setState({ showOptions: !showOptions });
              }}
            >
              <span className={currentText ? styles.currentText : ''}>
                {currentText
                  ? currentText
                  : placeholder
                  ? placeholder
                  : 'Select an option'}
              </span>
              <div>
                <img
                  src={
                    showOptions
                      ? require('../../../assets/images/Chevron.png')
                      : require('../../../assets/images/downChevron.png')
                  }
                  alt={showOptions ? 'dropdown open' : 'dropdown closed'}
                />
              </div>
            </li>
            <li className={showOptions ? '' : styles.hideOut}>
              <ul>
                {!hideMaps && (
                  <li
                    onMouseDown={() =>
                      this.handleSelectionChange({ label: 'All Maps' })
                    }
                  >
                    <div>All Maps</div>
                  </li>
                )}
                {options &&
                  options.map((option, index) => (
                    <li
                      key={index}
                      onMouseDown={() => this.handleSelectionChange(option)}
                    >
                      <div>{option.label}</div>
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
