const dev = {
  apiUrl: 'http://localhost:9000',
  webUrl: 'http://localhost:9000',
  googleClientId:
    '722069776185-cq1aqj4crio7k9od560bnfffu5g2lijn.apps.googleusercontent.com'
};

const prod = {
  apiUrl: 'https://mapping.topgunhq.com',
  webUrl: 'https://mapping.topgunhq.com',
  googleClientId:
    '664573449811-6g7gce1rg1r2vmj3s1p55sifu2g856hl.apps.googleusercontent.com'
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
