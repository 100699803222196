import React from 'react';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default function UserCardNav({
  updateUser,
  deleteUser,
  duplicateCard,
  disabled,
  openLinkingModal,
  openUnlinkingModal
}) {
  return (
    <>
      <div className={styles.userCardNav}>
        <div>
          <Button
            color="gray-gradient"
            showImage
            image={require('../../assets/images/deleteBlack.png')}
            handleClick={deleteUser}
            userCards
            disabled={disabled}
          />
        </div>
        <div>
          <Button
            color="gray-gradient"
            showImage
            image={require('../../assets/images/breakLink.png')}
            handleClick={openUnlinkingModal}
            userCards
            disabled={disabled}
          />
          <Button
            color="gray-gradient"
            showImage
            image={require('../../assets/images/link.png')}
            handleClick={openLinkingModal}
            userCards
            disabled={disabled}
          />
          <Button
            color="gray-gradient"
            showImage
            image={require('../../assets/images/duplicateBlack.png')}
            handleClick={duplicateCard}
            userCards
            disabled={disabled}
          />
          <Button
            color="gray-gradient"
            showImage
            image={require('../../assets/images/edit.png')}
            handleClick={updateUser}
            userCards
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}
