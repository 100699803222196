import React, { Component } from 'react';
import Dropdown from '../inputs/Dropdown';
import Modal from './index';

export default class LinkCardModal extends Component {
  state = {
    cardToLink: ''
  };

  handleSubmit = () => {
    const { currentCard } = this.props;
    const { cardToLink } = this.state;

    this.props.submitActions({ to: cardToLink.value, from: currentCard._id });
    this.setState({ cardToLink: { label: '', value: '' } });
  };

  handleCloseActions = () => {
    this.setState({ cardToLink: { label: '', value: '' } });
    this.props.closeActions();
  };

  render() {
    const { isOpen, userCards, currentCard, lines } = this.props;
    const { cardToLink } = this.state;

    const userDropdown = userCards
      .filter(
        (card) =>
          card._id !== currentCard._id &&
          lines.findIndex(
            (line) =>
              (line.from === currentCard._id && line.to === card._id) ||
              (line.from === card._id && line.to === currentCard._id)
          ) === -1
      )
      .map((card) => {
        return {
          label: card.name,
          value: card._id
        };
      });

    return (
      <>
        <Modal
          title="Link Card"
          isOpen={isOpen}
          text="Link Card"
          closeActions={this.handleCloseActions}
          submitActions={this.handleSubmit}
        >
          <Dropdown
            label="User"
            options={userDropdown}
            handleChange={(values) => this.setState({ cardToLink: values })}
            hideMaps
            currentValue={cardToLink}
          />
        </Modal>
      </>
    );
  }
}
