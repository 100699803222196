import React from 'react';

import styles from './styles.module.scss';

export default function CannotSaveDataModal({ isOpen, closeActions }) {
  const className = isOpen
    ? [styles.modal, styles.modalShow]
    : [styles.modal, styles.modalHide];

  return (
    <>
      <div className={className.join(' ')}>
        <div className={styles.modalBody}>
          <header>
            <div>Cannnot Save Map</div>
            <div className={styles.close} onClick={closeActions}>
              <div>X</div>
            </div>
          </header>
          <div>
            You will not be able to save data without creating an account
          </div>
        </div>
      </div>
    </>
  );
}
