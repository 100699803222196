import {
  FETCH_LINES_REQUEST,
  FETCH_LINES_RESPONSE,
  LINK_CARDS_REQUEST,
  LINK_CARDS_RESPONSE,
  UNLINK_CARDS_REQUEST,
  UNLINK_CARDS_RESPONSE
} from '../constants/actionTypes';

const initialState = { loading: false, list: [] };

const folders = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LINES_REQUEST:
    case LINK_CARDS_REQUEST:
    case UNLINK_CARDS_REQUEST:
      return { ...state, loading: true };

    case FETCH_LINES_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };

    case LINK_CARDS_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };

    case UNLINK_CARDS_RESPONSE: {
      let lineList = state.list;

      lineList = lineList.filter((map) => map._id !== payload);

      return {
        ...state,
        loading: false,
        list: lineList
      };
    }

    default:
      return { ...state };
  }
};

export default folders;
