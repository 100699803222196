import React, { Component } from 'react';
import Modal from './index';
import Textarea from '../inputs/Textarea';
import Textbox from '../inputs/Textbox';

export default class EmailSalesCoach extends Component {
  state = {
    name: '',
    email: '',
    msg: ''
  };

  render() {
    const { isOpen, closeActions, submitActions } = this.props;
    const { name, email, msg } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        title="Contact a Sales Coach"
        text="Send Message"
        closeActions={closeActions}
        submitActions={() => submitActions(this.state)}
      >
        <div>
          <Textbox
            label="Your Name"
            currentValue={name}
            handleChange={(values) => this.setState({ name: values })}
          />
          <Textbox
            label="Your Email"
            currentValue={email}
            handleChange={(values) => this.setState({ email: values })}
          />
          <Textarea
            label="Comments/Questions"
            currentValue={msg}
            handleChange={(values) => this.setState({ msg: values })}
          />
        </div>
      </Modal>
    );
  }
}
