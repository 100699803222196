import { call, select, put } from 'redux-saga/effects';
import {
  fetchFoldersResponse,
  addFolderResponse,
  updateFolderResponse,
  deleteFolderResponse
} from '../actions/folders';
import { addMessage, removeMessage } from '../actions/messages';
import { getActiveUserSelector } from '../selectors/user';

export function* fetchFolders(api, { payload }) {
  try {
    const activeUser = yield select(getActiveUserSelector);

    const response = yield call(api.fetchFolders, {
      accountId: activeUser._id
    });

    yield put(fetchFoldersResponse(response));
  } catch (error) {}
}

export function* addFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeUser = yield select(getActiveUserSelector);

    const response = yield call(api.addFolder, {
      ...payload,
      accountId: activeUser._id
    });

    yield put(addFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Created Successfully' }));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* updateFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.updateFolder, {
      ...payload
    });

    yield put(updateFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Updated Successfully' }));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* deleteFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    yield put(deleteFolderResponse(payload));

    yield call(api.deleteFolder, payload);

    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}
