import React, { Component } from 'react';
import Button from '../Button';
import Textbox from '../Textbox';

import styles from './styles.module.scss';

export default class FileUpload extends Component {
  state = {
    image: ''
  };

  componentDidMount() {
    if (this.props.propImage) {
      this.setState({ image: this.props.propImage });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.propImage !== prevProps.propImage) {
      this.setState({ image: this.props.propImage });
    }
  }

  handleImageSelect = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    const base64data = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    base64data.then((response) => {
      this.setState({ image: response });
      this.props.handleChange(response);
    });
  };

  render() {
    const { image } = this.state;

    return (
      <>
        <div className={styles.fileUpload}>
          <div className={styles.sameLine}>
            <Textbox placeholder="Select An Image..." disabled />
            <Button
              color="gray-gradient"
              text="Select Image"
              handleClick={() => document.getElementById(`myImage`).click()}
            />
          </div>
          <input
            type="file"
            id="myImage"
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => this.handleImageSelect(e)}
          />
          <div className={styles.imageContainer}>
            {image ? <img src={image} alt="user pic" /> : ''}
          </div>
        </div>
      </>
    );
  }
}
