import { combineReducers } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import folders from './folders';
import lines from './lines';
import maps from './maps';
import messages from './messages';
import user from './user';
import usercards from './userCards';

const appReducer = combineReducers({
  folders,
  lines,
  maps,
  messages,
  user,
  usercards
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOG_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
