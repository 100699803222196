import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { forgotPasswordRequest } from '../../actions/user';
import { getMessagesSelector } from '../../selectors/messages';
import Button from '../../components/inputs/Button';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

export class NewUserRegistration extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    company: '',
    triedToSubmit: false,
    passwordsNoMatch: false
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  handleSubmit() {
    const { email } = this.state;
    if (!email) {
      this.setState({ triedToSubmit: true });
    } else {
      this.props.forgotPassword(this.state);
    }
  }

  render() {
    return (
      <>
        <header className={styles.topHeader} />
        <div className={styles.forgotPassword}>
          <header>INFLUENCE MAPPER</header>
          <Textbox
            placeholder="Email"
            fullWidth
            handleChange={(values) => this.setState({ email: values })}
          />
          <div className={styles.explationText}>
            Your password will be reset and you will receive a notification to
            either your phone or email with a new password to use to log in
          </div>
          <Button
            text="Reset"
            color="orange"
            handleClick={() => this.handleSubmit()}
            fullWidth
          />
          <div className={styles.bottomLinks}>
            <Link to="/login">Back to Login</Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: getMessagesSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload) => dispatch(forgotPasswordRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(NewUserRegistration)
);
