import React from 'react';
import { Rnd } from 'react-rnd';
import Legend from '../Legend';

import styles from './styles.module.scss';

export default function LegendModal({ isOpen, closeActions }) {
  const className = isOpen
    ? [styles.modal, styles.modalShow]
    : [styles.modal, styles.modalHide];

  return (
    <Rnd>
      <div className={className.join(' ')}>
        <div className={styles.modalBody}>
          <header>
            <div>
              <img
                src={require('../../assets/images/legendWhite.png')}
                alt="Legend Icon"
              />{' '}
              LEGEND
            </div>
            <div className={styles.close} onClick={closeActions}>
              <div>X</div>
            </div>
          </header>
          <div>
            <Legend buyerRoles={[]} buyerTypes={[]} noClick />
          </div>
        </div>
      </div>
    </Rnd>
  );
}
