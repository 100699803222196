import React from 'react';

import styles from './styles.module.scss';

export default function MapCard({
  handleClick,
  map,
  handleMoveFolder,
  editMap,
  deleteMap,
  duplicateMap,
  isFreeUser,
  handleShare
}) {
  return (
    <>
      <div className={styles.mapCard}>
        <div className={styles.mapIcon} onClick={handleClick}>
          <img alt="Open a map" />
        </div>
        <div className={styles.footer}>
          <div>{map.name}</div>
          <div className={styles.menu}>
            <div>
              <div className={styles.circles} />
              <div className={styles.circles} />
              <div className={styles.circles} />
            </div>
            <ul className={isFreeUser ? styles.freeUserList : styles.list}>
              <li onClick={deleteMap}>
                <img alt="Delete Map" />
                Delete
              </li>
              <li onClick={duplicateMap}>
                <img alt="Duplicate Map" />
                Duplicate
              </li>
              <li onClick={!isFreeUser && handleMoveFolder}>
                <img alt="Move To Folder" />
                Move to folder
              </li>
              {!isFreeUser && (
                <li onClick={handleShare}>
                  <img alt="Share Map" />
                  Share
                </li>
              )}
              <li onClick={editMap}>
                <img alt="Rename Map" />
                Rename
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
