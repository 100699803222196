import {
  ADD_USERCARD_REQUEST,
  ADD_USERCARD_RESPONSE,
  UPDATE_USERCARD_REQUEST,
  UPDATE_USERCARD_RESPONSE,
  DELETE_USERCARD_REQUEST,
  DELETE_USERCARD_RESPONSE,
  FETCH_USERCARDS_REQUEST,
  FETCH_USERCARDS_RESPONSE
} from '../constants/actionTypes';

const initialState = { loading: false, list: [] };

const usercards = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERCARDS_REQUEST:
    case ADD_USERCARD_REQUEST:
    case UPDATE_USERCARD_REQUEST:
    case DELETE_USERCARD_REQUEST:
      return { ...state, loading: true };

    case FETCH_USERCARDS_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };

    case ADD_USERCARD_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };

    case UPDATE_USERCARD_RESPONSE: {
      let userList = [...state.list];

      return {
        ...state,
        loading: false,
        list: userList.map((userCard) => {
          if (userCard._id === payload._id) {
            return {
              ...payload
            };
          }
          return userCard;
        })
      };
    }

    case DELETE_USERCARD_RESPONSE: {
      let userList = state.list;

      userList = userList.filter((user) => user._id !== payload._id);

      return {
        ...state,
        loading: false,
        list: userList
      };
    }

    default:
      return { ...state };
  }
};

export default usercards;
