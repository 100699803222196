import React, { Component } from 'react';
import Textbox from '../inputs/Textbox';
import Modal from './index';

import styles from './styles.module.scss';

export default class renameFolderModal extends Component {
  state = {
    name: ''
  };

  componentDidUpdate(prevProps) {
    if (this.props.oldName && prevProps.oldName !== this.props.oldName) {
      this.setState({ name: this.props.oldName });
    }
  }

  handleSubmit = () => {
    this.props.submitActions(this.state);
  };

  render() {
    const { isOpen, closeActions } = this.props;
    const { name } = this.state;

    return (
      <>
        <Modal
          title="Rename Folder"
          isOpen={isOpen}
          text="Rename Folder"
          submitImage={require('../../assets/images/newFolder.png')}
          closeActions={closeActions}
          submitActions={() => this.handleSubmit()}
        >
          <div>
            <div className={styles.row}>
              <Textbox
                currentValue={name}
                label="Folder Name"
                placeholder="Edit Folder"
                handleChange={(value) => this.setState({ name: value })}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
