import React, { Component } from 'react';
import Button from '../inputs/Button';
import Textbox from '../inputs/Textbox';
import Modal from './index';

import styles from './styles.module.scss';

export default class CreateFolderModal extends Component {
  state = {
    name: ''
  };

  handleSubmit = () => {
    this.props.submitActions(this.state);
    this.setState({ name: '' });
  };

  render() {
    const { isOpen, closeActions, folderColor, openColorPicker } = this.props;
    const { name } = this.state;

    return (
      <>
        <Modal
          title="Create Folder"
          isOpen={isOpen}
          text="Create Folder"
          submitImage={require('../../assets/images/newFolderWhite.png')}
          closeActions={closeActions}
          submitActions={() => this.handleSubmit()}
        >
          <div>
            <div className={styles.row}>
              <div className={styles.numbers}>
                <div>1</div>
              </div>
              <Button
                color="orange"
                text="Pick Folder Color"
                showImage
                image={require('../../assets/images/colorPickerWhite.png')}
                handleClick={openColorPicker}
              />
              <div
                className={styles.folder}
                style={{ backgroundColor: folderColor || 'white' }}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.numbers}>
                <div>2</div>
              </div>
              <Textbox
                label="Folder Name"
                currentValue={name}
                placeholder="New Folder"
                handleChange={(value) => this.setState({ name: value })}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
