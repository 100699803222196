import { createSelector } from 'reselect';

const foldersSelector = (state) => state.folders;

export const getFolderListSelector = createSelector(
  foldersSelector,
  (folders) => folders && folders.list
);

export const getActiveFolderSelector = createSelector(
  foldersSelector,
  (folders) => folders && folders.activeFolder
);
