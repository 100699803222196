import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import history from '../../history';
import {
  addMapRequest,
  updateMapRequest,
  deleteMapRequest,
  fetchMapsRequest,
  setActiveMap
} from '../../actions/maps';
import { emailSalesCoachRequest } from '../../actions/messages';
import { logout } from '../../actions/user';
import { getMapListSelector } from '../../selectors/maps';
import { getMessagesSelector } from '../../selectors/messages';
import { isFreeUserSelector } from '../../selectors/user';
import Button from '../../components/inputs/Button';
import DashboardHeader from '../../components/DashboardHeader';
import DeleteMapModal from '../../components/Modal/deleteMapModal';
import EditMapModal from '../../components/Modal/editMapModal';
import EmailSalesCoachModal from '../../components/Modal/emailSalesCoachModal';
import Get30QuestionsModal from '../../components/Get30QuestionsModal';
import MapCard from '../../components/MapCard';
import NewMapModal from '../../components/Modal/newMapModal';
import SortDropdown from '../../components/inputs/SortDropDown';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

class Dashboard extends Component {
  state = {
    openNewMapModal: false,
    openEditMapNameModal: false,
    openDeleteMapModal: false,
    openEmailSalesCoachModal: false,
    open30QuestionsModal: false,
    map: {},
    maps: [],
    openShareModal: false
  };

  componentDidMount() {
    this.props.fetchMaps();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (this.props.maps && prevProps.maps !== this.props.maps) {
      this.setState({ maps: this.props.maps });
    }
  }

  filterMaps(searchText) {
    let maps = [];
    if (searchText) {
      maps = this.state.maps.filter((map) =>
        map.name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      maps = this.props.maps;
    }

    this.setState({ maps });
  }

  sortMaps(option) {
    let maps = this.state.maps;

    if (option.text === 'Date Created') {
      maps = maps.sort((a, b) => {
        if (a.createdAt === b.createdAt) {
          return 0;
        }
        return a.createdAt > b.createdAt ? 1 : -1;
      });
    } else if (option.text === 'Alphabetical') {
      maps = maps.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      });
    } else if (option.text === 'Last Modified') {
      maps = maps.sort((a, b) => {
        if (a.updatedAt === b.updatedAt) {
          return 0;
        }
        return b.updatedAt > a.updatedAt ? 1 : -1;
      });
    }

    this.setState({ maps });
  }

  render() {
    const {
      openNewMapModal,
      openEditMapNameModal,
      openDeleteMapModal,
      openEmailSalesCoachModal,
      open30QuestionsModal,
      maps
    } = this.state;

    const {
      addMap,
      updateMap,
      deleteMap,
      setActiveMap,
      logout,
      emailSalesCoach
    } = this.props;

    const breadCrumbs = ['All Maps'];

    return (
      <>
        <div className={styles.dashboard}>
          <DashboardHeader
            logout={logout}
            isFreeUser={true}
            openEmailCoachModal={() =>
              this.setState({ openEmailSalesCoachModal: true })
            }
            openQuestionsModal={() =>
              this.setState({ open30QuestionsModal: true })
            }
          />
          <header className={styles.subNav}>
            <div className={styles.breadCrumbs}>{breadCrumbs.join(' > ')}</div>
            <div className={styles.actionItems}>
              <Textbox
                placeholder="Search Maps"
                handleChange={(values) => this.filterMaps(values)}
              />
              <Button
                color="orange"
                text="New Map"
                showImage
                image={require('../../assets/images/mapIconWhite.png')}
                handleClick={() => this.setState({ openNewMapModal: true })}
              />
              <Button
                color="gray-gradient"
                showImage
                image={require('../../assets/images/newFolder.png')}
                handleClick={() =>
                  this.setState({
                    openCreateFolderModal: true,
                    folderColor: '#fff'
                  })
                }
                noPadding
                disabled={true}
              />
              <SortDropdown handleChange={(option) => this.sortMaps(option)} />
            </div>
          </header>
          <div className={styles.mainContent}>
            <div>
              {maps.map((map, index) => (
                <MapCard
                  key={index}
                  map={map}
                  handleClick={() => {
                    setActiveMap(map);
                    history.push('/freeCardMapView');
                  }}
                  handleMoveFolder={() =>
                    this.setState({ openMoveToFolderModal: true, map })
                  }
                  editMap={() =>
                    this.setState({ map, openEditMapNameModal: true })
                  }
                  deleteMap={() =>
                    this.setState({ map, openDeleteMapModal: true })
                  }
                  dupicateMap={() => addMap(map)}
                  isFreeUser
                />
              ))}
            </div>
          </div>
        </div>
        <NewMapModal
          isOpen={openNewMapModal}
          closeActions={() => this.setState({ openNewMapModal: false })}
          submitActions={(values) =>
            addMap(values) && this.setState({ openNewMapModal: false })
          }
        />
        <EditMapModal
          isOpen={openEditMapNameModal}
          closeActions={() => this.setState({ openEditMapNameModal: false })}
          submitActions={(values) =>
            updateMap({ ...this.state.map, name: values.name }) &&
            this.setState({ openEditMapNameModal: false, map: {} })
          }
          oldName={this.state.map.name}
        />
        <DeleteMapModal
          isOpen={openDeleteMapModal}
          closeActions={() => this.setState({ openDeleteMapModal: false })}
          submitActions={() =>
            deleteMap(this.state.map) &&
            this.setState({ openDeleteMapModal: false, map: {} })
          }
          mapname={this.state.map.name}
        />
        <EmailSalesCoachModal
          isOpen={openEmailSalesCoachModal}
          closeActions={() =>
            this.setState({ openEmailSalesCoachModal: false })
          }
          submitActions={(values) => {
            emailSalesCoach(values);
            this.setState({ openEmailSalesCoachModal: false });
          }}
        />
        <Get30QuestionsModal
          isOpen={open30QuestionsModal}
          closeActions={() => this.setState({ open30QuestionsModal: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  maps: getMapListSelector(state),
  message: getMessagesSelector(state),
  isFreeUser: isFreeUserSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  addMap: (payload) => dispatch(addMapRequest(payload)),
  updateMap: (payload) => dispatch(updateMapRequest(payload)),
  deleteMap: (payload) => dispatch(deleteMapRequest(payload)),
  fetchMaps: (payload) => dispatch(fetchMapsRequest(payload)),
  setActiveMap: (payload) => dispatch(setActiveMap(payload)),
  logout: (payload) => dispatch(logout(payload)),
  emailSalesCoach: (payload) => dispatch(emailSalesCoachRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
