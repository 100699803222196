import React from 'react';
import { influenceMapper } from '../../utils/userCardHelper';

export default function DataPoint({ payload }) {
  return (
    <>
      <svg
        viewBox="0 0 50 50"
        width={30}
        height={30}
        style={{ overflow: 'visible' }}
      >
        <circle
          r="25"
          fill={influenceMapper(payload.buyerPower, payload.friendFoe).color}
          fillOpacity="0"
          stroke="black"
          cx={25}
          cy={10}
        />
        <svg
          width="24"
          height="27"
          viewBox="0 0 24 27"
          fill="black"
          x={12}
          y={-3}
        >
          <path
            d="M18.4998 7.50064C18.5119 8.35758 18.3521 9.20826 18.0297 10.0023C17.7073 10.7964 17.229 11.5178 16.623 12.1238C16.017 12.7298 15.2956 13.2082 14.5015 13.5305C13.7074 13.8529 12.8567 14.0128 11.9998 14.0006C11.1474 14.0208 10.2997 13.8692 9.50727 13.5547C8.71478 13.2402 7.99375 12.7694 7.38715 12.1703C6.78055 11.5711 6.30082 10.856 5.97656 10.0675C5.6523 9.27893 5.49016 8.43318 5.4998 7.58064C5.4865 6.71961 5.64496 5.86457 5.96591 5.06548C6.28686 4.26639 6.76386 3.53929 7.36903 2.92667C7.97421 2.31404 8.69542 1.82818 9.49053 1.49748C10.2856 1.16678 11.1387 0.997881 11.9998 1.00064C12.8567 0.988522 13.7074 1.14837 14.5015 1.47074C15.2956 1.7931 16.017 2.27143 16.623 2.87745C17.229 3.48346 17.7073 4.20484 18.0297 4.99893C18.3521 5.79302 18.5119 6.6437 18.4998 7.50064Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 26.0006C1 19.7406 5.81 14.2106 12.07 14.2106C18.33 14.2106 23 19.7406 23 26.0006"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </svg>
    </>
  );
}
