import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import './index.css';

export default class Checkbox extends Component {
  state = {
    currentSelected: ''
  };

  componentDidMount() {
    const { currentValue } = this.props;

    if (currentValue && currentValue.length > 0) {
      this.setState({
        currentSelected: currentValue
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { currentValue } = this.props;

    if (currentValue !== prevProps.currentValue) {
      this.setState({ currentSelected: currentValue });
    }
  };

  handleChange = (newValue) => {
    const { handleChange } = this.props;

    handleChange(newValue);
    this.setState({ currentSelected: newValue });
  };

  render() {
    const { name, label, options, isRequired, hideLabel } = this.props;

    const { currentSelected } = this.state;

    return (
      <div className="input">
        {label && (
          <label className="label" htmlFor={name}>
            {isRequired ? <span className="isRequired">*</span> : ''}
            {label}
          </label>
        )}
        <div className="checkboxColumns">
          {options.map((option, index) => (
            <div>
              <div className="checkboxinput" key={index}>
                <div className="checkboxDiv">
                  <label
                    className={
                      option.value >= 0
                        ? 'checkbox-label--morePadding'
                        : 'checkbox-label'
                    }
                    data-tip={option.tooltipName}
                    data-for={option.tooltipName}
                  >
                    {!hideLabel && option.label}
                    <input
                      type="checkbox"
                      name={`${name}[${index}]`}
                      value={option.value}
                      checked={currentSelected === option.value}
                      onChange={(event) => {
                        let newValue = '';
                        if (event.target.checked) {
                          newValue = option.value;
                        }

                        this.handleChange(newValue);
                      }}
                    />
                    <div className="checkbox" />
                  </label>
                </div>
              </div>
              <ReactTooltip
                className="customTheme"
                place="bottom"
                effect="solid"
                type="light"
                id={option.tooltipName}
              >
                <div>{option.tooltip}</div>
              </ReactTooltip>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
