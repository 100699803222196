import React, { Component } from 'react';
import { SORTDROPDOWN_OPTIONS } from '../../../constants/constants';
import Button from '../Button';

import styles from './styles.module.scss';

export default class SortDropDown extends Component {
  state = {
    showOptions: false,
    currentSelected: {
      image: require('../../../assets/images/createdByDesc.png'),
      text: 'Date Created'
    }
  };

  handleClick(option) {
    this.props.handleChange(option);
    this.setState({ showOptions: false, currentSelected: { ...option } });
  }

  render() {
    const { currentSelected, showOptions } = this.state;

    const optionsClasses = showOptions
      ? [styles.dropdownOptions, styles.showOptions]
      : [styles.dropdownOptions];

    const overallClasses = showOptions
      ? [styles.sortDropdown, styles.sortDropdownShowOptions]
      : [styles.sortDropdown];

    return (
      <>
        <div className={overallClasses.join(' ')}>
          <Button
            color="gray-gradient"
            text={currentSelected.text}
            showImage
            image={currentSelected.image}
            addChevron
            handleClick={() => this.setState({ showOptions: !showOptions })}
          />
          <div className={optionsClasses.join(' ')}>
            {SORTDROPDOWN_OPTIONS.map((option, index) => (
              <div onClick={() => this.handleClick(option)} key={index}>
                <img src={option.image} alt={option.text} />
                <div>{option.text}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
