import {
  LOG_IN,
  LOG_OUT,
  ADD_USER_REQUEST,
  ADD_USER_RESPONSE,
  ADD_USER_AND_MAP_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  FREE_USER_REQUEST,
  LOGIN_GOOGLE,
  FORGOT_PASSWORD_REQUEST
} from '../constants/actionTypes';

export const login = (payload) => ({
  type: LOG_IN,
  payload
});

export const googleLogin = (payload) => ({
  type: LOGIN_GOOGLE,
  payload
});

export const logout = (payload) => ({
  type: LOG_OUT,
  payload
});

export const addUserRequest = (payload) => ({
  type: ADD_USER_REQUEST,
  payload
});

export const addUserResponse = (payload) => ({
  type: ADD_USER_RESPONSE,
  payload
});

export const addUserAndMapRequest = (payload) => ({
  type: ADD_USER_AND_MAP_REQUEST,
  payload
});

export const updateUserRequest = (payload) => ({
  type: UPDATE_USER_REQUEST,
  payload
});

export const deleteUserRequest = (payload) => ({
  type: DELETE_USER_REQUEST,
  payload
});

export const freeUserRequest = (payload) => ({
  type: FREE_USER_REQUEST,
  payload
});

export const forgotPasswordRequest = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload
});
