import React, { Component } from 'react';
import Dropdown from '../inputs/Dropdown';
import Modal from './index';

export default class UnlinkCardModal extends Component {
  state = {
    cardToUnLink: ''
  };

  handleSubmit = () => {
    const { lines, currentCard } = this.props;
    const { cardToUnLink } = this.state;

    const line = lines.find(
      (line) =>
        (line.from === currentCard._id && line.to === cardToUnLink.value) ||
        (line.from === cardToUnLink.value && line.to === currentCard._id)
    );

    this.props.submitActions(line?._id);
    this.setState({ cardToUnLink: { label: '', value: '' } });
  };

  handleCloseActions = () => {
    this.setState({ cardToUnLink: { label: '', value: '' } });
    this.props.closeActions();
  };

  render() {
    const { isOpen, userCards, currentCard, lines } = this.props;
    const { cardToUnLink } = this.state;

    const userDropdown = userCards
      .filter(
        (card) =>
          card._id !== currentCard._id &&
          lines.findIndex(
            (line) =>
              (line.from === currentCard._id && line.to === card._id) ||
              (line.from === card._id && line.to === currentCard._id)
          ) > -1
      )
      .map((card) => {
        return {
          label: card.name,
          value: card._id
        };
      });

    return (
      <>
        <Modal
          title="Unlink Card"
          isOpen={isOpen}
          text="Unlink Card"
          closeActions={this.handleCloseActions}
          submitActions={this.handleSubmit}
        >
          <Dropdown
            label="User"
            options={userDropdown}
            handleChange={(values) => this.setState({ cardToUnLink: values })}
            hideMaps
            currentValue={cardToUnLink}
          />
        </Modal>
      </>
    );
  }
}
