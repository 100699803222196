import { call, select, put } from 'redux-saga/effects';
import {
  linkCardsResponse,
  fetchLinesResponse,
  unlinkCardsResponse
} from '../actions/lines';
import { addMessage, removeMessage } from '../actions/messages';
import { getLinesListSelector } from '../selectors/lines';
import { getActiveMapSelector } from '../selectors/maps';
import { isFreeUserSelector } from '../selectors/user';

export function* fetchLines(api) {
  try {
    yield put(removeMessage());
    const activeMap = yield select(getActiveMapSelector);
    const freeUser = yield select(isFreeUserSelector);
    let response;

    if (freeUser) {
      const allLines = yield select(getLinesListSelector);
      response = allLines.filter((line) => line.mapId === activeMap._id);
    } else {
      response = yield call(api.fetchLines, {
        accountId: activeMap.accountId,
        mapId: activeMap._id
      });
    }

    yield put(fetchLinesResponse(response));
  } catch (error) {
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* linkCards(api, { payload }) {
  try {
    yield put(removeMessage());
    const freeUser = yield select(isFreeUserSelector);
    const activeMap = yield select(getActiveMapSelector);
    let response;

    if (freeUser) {
      const lineList = yield select(getLinesListSelector);
      response = { ...payload, _id: lineList.length, mapId: activeMap._id };
    } else {
      response = yield call(api.addLine, {
        ...payload,
        accountId: activeMap.accountId,
        mapId: activeMap._id
      });
    }

    yield put(linkCardsResponse(response));
  } catch (error) {
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* unlinkCards(api, { payload }) {
  try {
    yield put(removeMessage());
    const freeUser = yield select(isFreeUserSelector);

    if (!freeUser) {
      yield call(api.deleteLine, payload);
    }

    yield put(unlinkCardsResponse(payload));
  } catch (error) {
    yield put(addMessage({ error: true, message: error }));
  }
}
