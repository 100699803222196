import {
  ADD_MAP_REQUEST,
  ADD_MAP_RESPONSE,
  UPDATE_MAP_REQUEST,
  UPDATE_MAP_RESPONSE,
  DELETE_MAP_REQUEST,
  DELETE_MAP_RESPONSE,
  FETCH_MAPS_REQUEST,
  FETCH_MAPS_RESPONSE,
  SET_ACTIVE_MAP
} from '../constants/actionTypes';

export const fetchMapsRequest = (payload) => ({
  type: FETCH_MAPS_REQUEST,
  payload
});

export const fetchMapsResponse = (payload) => ({
  type: FETCH_MAPS_RESPONSE,
  payload
});

export const addMapRequest = (payload) => ({
  type: ADD_MAP_REQUEST,
  payload
});

export const addMapResponse = (payload) => ({
  type: ADD_MAP_RESPONSE,
  payload
});

export const updateMapRequest = (payload) => ({
  type: UPDATE_MAP_REQUEST,
  payload
});

export const updateMapResponse = (payload) => ({
  type: UPDATE_MAP_RESPONSE,
  payload
});

export const deleteMapRequest = (payload) => ({
  type: DELETE_MAP_REQUEST,
  payload
});

export const deleteMapResponse = (payload) => ({
  type: DELETE_MAP_RESPONSE,
  payload
});

export const setActiveMap = (payload) => ({
  type: SET_ACTIVE_MAP,
  payload
});
