import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { freeUserRequest, login, googleLogin } from '../../actions/user';
import config from '../../config/config';
import { getMessagesSelector } from '../../selectors/messages';
import Button from '../../components/inputs/Button';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

export class Login extends Component {
  state = {
    email: '',
    password: ''
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  render() {
    const { freeUser, login, googleLogIn } = this.props;

    return (
      <>
        <header className={styles.topHeader} />
        <div className={styles.login}>
          <header>INFLUENCE MAPPER</header>
          <Button
            text="Try it Out"
            color="gray-gradient"
            showImage
            image={require('../../assets/images/SignIn.png')}
            handleClick={() => freeUser()}
            fullWidth
          />
          <div className={styles.explationText}>
            Maps cannot be saved or shared from the free trial version.
          </div>
          <hr />
          <GoogleLogin
            clientId={config.googleClientId}
            buttonText="Sign In with Google"
            onSuccess={(values) =>
              googleLogIn({
                ...values,
                referrer: this.props.location?.state?.referrer
              })
            }
            onFailure={googleLogIn}
            cookiePolicy={'single_host_origin'}
            theme="dark"
          />
          <div className={styles.explationText}>or</div>
          <Textbox
            placeholder="Email"
            fullWidth
            handleChange={(values) => this.setState({ email: values })}
          />
          <Textbox
            placeholder="Password"
            type="password"
            fullWidth
            handleChange={(values) => this.setState({ password: values })}
          />
          <Button
            text="Sign In"
            color="orange"
            showImage
            image={require('../../assets/images/SignIn.png')}
            handleClick={() =>
              login({
                ...this.state,
                referrer: this.props.location?.state?.referrer
              })
            }
            fullWidth
          />
          <div className={styles.bottomLinks}>
            <Link
              to={{
                pathname: '/newUserRegistration',
                state: { referrer: this.props.location?.state?.referrer }
              }}
            >
              Don't have an account?
            </Link>
            <Link to="/forgotPassword">Forgot Password</Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: getMessagesSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  freeUser: () => dispatch(freeUserRequest({ freeUser: true })),
  login: (payload) => dispatch(login(payload)),
  googleLogIn: (payload) => dispatch(googleLogin(payload))
});

export default withAlert(connect(mapStateToProps, mapDispatchToProps)(Login));
