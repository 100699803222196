import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  mapGridColor,
  mapUserToGrid,
  mapInformationalText
} from '../../utils/strategyMapHelper';
import DataPoint from './dataPoint';

import styles from './styles.module.scss';

export default function QuadView({ userCards }) {
  return (
    <>
      <div className={styles.quadView}>
        <div className={styles.gridContainer}>
          <div className={styles.leftSide}>
            {Array.apply(null, Array(6)).map((dataPoint, index) => (
              <div className={[styles.number].join(' ')} key={index}>
                {index === 5 ? '?' : Math.abs(index - 4)}
              </div>
            ))}
          </div>
          {Array.apply(null, Array(54)).map((dataPoint, index) => (
            <div
              className={[
                styles.gridItem,
                styles[mapGridColor(parseInt(index, 10))]
              ].join(' ')}
              key={index}
            >
              {mapInformationalText(index) && (
                <div className={styles.infoText}>
                  {mapInformationalText(index).header}
                  <img
                    data-tip={index.toString() + 'info'}
                    data-for={index.toString() + 'info'}
                    src={require('../../assets/images/moreInfo.png')}
                    alt={mapInformationalText(index).header}
                  />
                  <ReactTooltip
                    className={styles.customTheme}
                    place="bottom"
                    effect="solid"
                    type="light"
                    id={index.toString() + 'info'}
                  >
                    <h2>{mapInformationalText(index).header}</h2>
                    {mapInformationalText(index).text}
                  </ReactTooltip>
                </div>
              )}
              {mapUserToGrid(parseInt(index, 10), userCards)?.length > 0 ? (
                <div
                  className={styles.dataPointContainer}
                  data-tip={index.toString()}
                  data-for={index.toString()}
                >
                  <DataPoint
                    payload={mapUserToGrid(parseInt(index, 10), userCards)[0]}
                    numberOfPeople={
                      mapUserToGrid(parseInt(index, 10), userCards)?.length
                    }
                  />
                  <div>
                    {mapUserToGrid(parseInt(index, 10), userCards)?.length > 1
                      ? 'Multiple People'
                      : mapUserToGrid(parseInt(index, 10), userCards)[0].name}
                  </div>
                  {mapUserToGrid(parseInt(index, 10), userCards)?.length >
                    1 && (
                    <ReactTooltip
                      className={styles.customTheme}
                      place="bottom"
                      effect="solid"
                      type="light"
                      id={index.toString()}
                    >
                      <ul className={styles.dataList}>
                        {mapUserToGrid(parseInt(index, 10), userCards).map(
                          (user, index) => (
                            <li key={index}>{user.name}</li>
                          )
                        )}
                      </ul>
                    </ReactTooltip>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
          <div className={styles.bottom}>
            {Array.apply(null, Array(9)).map((dataPoint, index) => (
              <div className={[styles.number].join(' ')} key={index}>
                {index - 4}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
