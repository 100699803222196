import {
  ADD_MAP_REQUEST,
  ADD_MAP_RESPONSE,
  UPDATE_MAP_REQUEST,
  UPDATE_MAP_RESPONSE,
  DELETE_MAP_REQUEST,
  DELETE_MAP_RESPONSE,
  FETCH_MAPS_REQUEST,
  FETCH_MAPS_RESPONSE,
  SET_ACTIVE_MAP
} from '../constants/actionTypes';

const initialState = { loading: false, list: [], activeMap: {} };

const maps = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MAPS_REQUEST:
    case ADD_MAP_REQUEST:
    case UPDATE_MAP_REQUEST:
    case DELETE_MAP_REQUEST:
      return { ...state, loading: true };

    case FETCH_MAPS_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };

    case ADD_MAP_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };

    case UPDATE_MAP_RESPONSE: {
      let mapList = [...state.list];

      return {
        ...state,
        loading: false,
        list: mapList.map((map) => {
          if (map._id === payload._id) {
            return {
              ...payload
            };
          }
          return map;
        })
      };
    }

    case DELETE_MAP_RESPONSE: {
      let mapList = state.list;

      mapList = mapList.filter((map) => map._id !== payload._id);

      return {
        ...state,
        loading: false,
        list: mapList
      };
    }

    case SET_ACTIVE_MAP:
      return {
        ...state,
        activeMap: payload
      };

    default:
      return { ...state };
  }
};

export default maps;
