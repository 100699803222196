export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

export const ADD_USER_AND_MAP_REQUEST = 'ADD_USER_AND_MAP_REQUEST';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_RESPONSE = 'ADD_USER_RESPONSE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';

export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';

export const FREE_USER_REQUEST = 'FREE_USER_REQUEST';

export const FETCH_MAPS_REQUEST = 'FETCH_MAPS_REQUEST';
export const FETCH_MAPS_RESPONSE = 'FETCH_MAPS_RESPONSE';

export const ADD_MAP_REQUEST = 'ADD_MAP_REQUEST';
export const ADD_MAP_RESPONSE = 'ADD_MAP_RESPONSE';

export const UPDATE_MAP_REQUEST = 'UPDATE_MAP_REQUEST';
export const UPDATE_MAP_RESPONSE = 'UPDATE_MAP_RESPONSE';

export const DELETE_MAP_REQUEST = 'DELETE_MAP_REQUEST';
export const DELETE_MAP_RESPONSE = 'DELETE_MAP_RESPONSE';

export const SET_ACTIVE_MAP = 'SET_ACTIVE_MAP';

export const FETCH_FOLDERS_REQUEST = 'FETCH_FOLDERS_REQUEST';
export const FETCH_FOLDERS_RESPONSE = 'FETCH_FOLDERS_RESPONSE';

export const ADD_FOLDER_REQUEST = 'ADD_FOLDER_REQUEST';
export const ADD_FOLDER_RESPONSE = 'ADD_FOLDER_RESPONSE';

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST';
export const UPDATE_FOLDER_RESPONSE = 'UPDATE_FOLDER_RESPONSE';

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_RESPONSE = 'DELETE_FOLDER_RESPONSE';

export const SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER';

export const FETCH_USERCARDS_REQUEST = 'FETCH_USERCARDS_REQUEST';
export const FETCH_USERCARDS_RESPONSE = 'FETCH_USERCARDS_RESPONSE';

export const FETCH_MAP_AND_CARDS_REQUEST = 'FETCH_MAP_AND_CARDS_REQUEST';

export const ADD_USERCARD_REQUEST = 'ADD_USERCARD_REQUEST';
export const ADD_USERCARD_RESPONSE = 'ADD_USERCARD_RESPONSE';

export const UPDATE_USERCARD_REQUEST = 'UPDATE_USERCARD_REQUEST';
export const UPDATE_USERCARD_RESPONSE = 'UPDATE_USERCARD_RESPONSE';

export const DELETE_USERCARD_REQUEST = 'DELETE_USERCARD_REQUEST';
export const DELETE_USERCARD_RESPONSE = 'DELETE_USERCARD_RESPONSE';

export const SAVE_ALL_USERCARDS_REQUEST = 'SAVE_ALL_USERCARDS_REQUEST';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const EMAIL_SALES_COACH_REQUEST = 'EMAIL_SALES_COACH_REQUEST';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

export const FETCH_LINES_REQUEST = 'FETCH_LINES_REQUEST';
export const FETCH_LINES_RESPONSE = 'FETCH_LINES_RESPONSE';

export const LINK_CARDS_REQUEST = 'LINK_CARDS_REQUEST';
export const LINK_CARDS_RESPONSE = 'LINK_CARDS_RESPONSE';

export const UNLINK_CARDS_REQUEST = 'UNLINK_CARDS_REQUEST';
export const UNLINK_CARDS_RESPONSE = 'UNLINK_CARDS_RESPONSE';
