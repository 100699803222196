import { createSelector } from 'reselect';

const mapsSelector = (state) => state.maps;

export const getMapListSelector = createSelector(
  mapsSelector,
  (maps) => maps && maps.list
);

export const getActiveMapSelector = createSelector(
  mapsSelector,
  (maps) => maps && maps.activeMap
);
