import React from 'react';
import Dropdown from '../inputs/Dropdown';
import Modal from './index';

import styles from './styles.module.scss';

export default function MoveToFolderModal({
  isOpen,
  closeActions,
  submitActions,
  folders,
  handleChange
}) {
  return (
    <>
      <Modal
        title="Move to folder"
        isOpen={isOpen}
        text="Move Map"
        submitImage={require('../../assets/images/mapIconWhite.png')}
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          <div className={styles.row}>
            <Dropdown
              label="Folder"
              options={folders.map((folder) => {
                return {
                  label: folder.name,
                  value: folder._id
                };
              })}
              handleChange={(values) =>
                handleChange({ folderId: values.value })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
