import {
  FETCH_LINES_REQUEST,
  FETCH_LINES_RESPONSE,
  LINK_CARDS_REQUEST,
  LINK_CARDS_RESPONSE,
  UNLINK_CARDS_REQUEST,
  UNLINK_CARDS_RESPONSE
} from '../constants/actionTypes';

export const fetchLinesRequest = (payload) => ({
  type: FETCH_LINES_REQUEST,
  payload
});

export const fetchLinesResponse = (payload) => ({
  type: FETCH_LINES_RESPONSE,
  payload
});

export const linkCardsRequest = (payload) => ({
  type: LINK_CARDS_REQUEST,
  payload
});

export const linkCardsResponse = (payload) => ({
  type: LINK_CARDS_RESPONSE,
  payload
});

export const unlinkCardsRequest = (payload) => ({
  type: UNLINK_CARDS_REQUEST,
  payload
});

export const unlinkCardsResponse = (payload) => ({
  type: UNLINK_CARDS_RESPONSE,
  payload
});
