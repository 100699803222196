import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.scss';

export default function Button({
  image,
  text,
  color,
  type,
  handleClick,
  disabled,
  showImage,
  fullWidth,
  noPadding,
  userCards,
  children,
  name,
  displayTooltip,
  addChevron
}) {
  const classes = fullWidth
    ? [styles.button, styles[color], styles.fullWidth]
    : noPadding
    ? [styles.button, styles[color], styles.noPadding]
    : userCards
    ? [styles.button, styles[color], styles.userCards]
    : [styles.button, styles[color]];

  return (
    <>
      <button
        className={classes.join(' ')}
        onClick={handleClick}
        type={type || 'button'}
        disabled={disabled}
        data-tip={name}
        data-for={name}
      >
        {showImage && <img src={image} alt={text} />}
        {text && <div>{text}</div>}
        {addChevron && (
          <img
            src={require('../../../assets/images/chevronDown.png')}
            alt="Click to sort by"
            className={styles.chevron}
          />
        )}
      </button>
      {displayTooltip && (
        <ReactTooltip
          className={styles.customTheme}
          place="bottom"
          effect="solid"
          type="light"
          id={name}
        >
          {children}
        </ReactTooltip>
      )}
    </>
  );
}
