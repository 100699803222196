import { takeLatest } from 'redux-saga/effects';
import * as api from '../api';
import * as actionTypes from '../constants/actionTypes';
import { addFolder, updateFolder, deleteFolder, fetchFolders } from './folders';
import { fetchLines, linkCards, unlinkCards } from './lines';
import { addMap, updateMap, deleteMap, fetchMaps } from './maps';
import {
  freeUser,
  logInUser,
  newUserRegistration,
  updateUser,
  googleLogInUser,
  sendCoachEmail,
  forgotPassword,
  addUserAndMap
} from './user';
import {
  addUserCard,
  updateUserCard,
  deleteUserCard,
  fetchUserCards,
  fetchMapsAndUserCards,
  saveAllUserCards
} from './userCards';

export default function* rootSaga() {
  yield takeLatest(actionTypes.FREE_USER_REQUEST, freeUser, api);

  yield takeLatest(actionTypes.LOG_IN, logInUser, api);

  yield takeLatest(actionTypes.LOGIN_GOOGLE, googleLogInUser, api);

  yield takeLatest(actionTypes.ADD_USER_REQUEST, newUserRegistration, api);

  yield takeLatest(actionTypes.ADD_USER_AND_MAP_REQUEST, addUserAndMap, api);

  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser, api);

  yield takeLatest(actionTypes.FETCH_USERCARDS_REQUEST, fetchUserCards, api);

  yield takeLatest(
    actionTypes.FETCH_MAP_AND_CARDS_REQUEST,
    fetchMapsAndUserCards,
    api
  );

  yield takeLatest(actionTypes.ADD_USERCARD_REQUEST, addUserCard, api);

  yield takeLatest(actionTypes.UPDATE_USERCARD_REQUEST, updateUserCard, api);

  yield takeLatest(actionTypes.DELETE_USERCARD_REQUEST, deleteUserCard, api);

  yield takeLatest(
    actionTypes.SAVE_ALL_USERCARDS_REQUEST,
    saveAllUserCards,
    api
  );

  yield takeLatest(actionTypes.FETCH_MAPS_REQUEST, fetchMaps, api);

  yield takeLatest(actionTypes.ADD_MAP_REQUEST, addMap, api);

  yield takeLatest(actionTypes.UPDATE_MAP_REQUEST, updateMap, api);

  yield takeLatest(actionTypes.DELETE_MAP_REQUEST, deleteMap, api);

  yield takeLatest(actionTypes.FETCH_FOLDERS_REQUEST, fetchFolders, api);

  yield takeLatest(actionTypes.ADD_FOLDER_REQUEST, addFolder, api);

  yield takeLatest(actionTypes.UPDATE_FOLDER_REQUEST, updateFolder, api);

  yield takeLatest(actionTypes.DELETE_FOLDER_REQUEST, deleteFolder, api);

  yield takeLatest(actionTypes.EMAIL_SALES_COACH_REQUEST, sendCoachEmail, api);

  yield takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api);

  yield takeLatest(actionTypes.FETCH_LINES_REQUEST, fetchLines, api);

  yield takeLatest(actionTypes.LINK_CARDS_REQUEST, linkCards, api);

  yield takeLatest(actionTypes.UNLINK_CARDS_REQUEST, unlinkCards, api);
}
