import React from 'react';
import GoogleLogin from 'react-google-login';
import config from '../../config/config';
import Modal from './index';
import Textbox from '../inputs/Textbox';

import styles from './styles.module.scss';

export default function CreateUserAccountModal({
  isOpen,
  closeActions,
  submitActions,
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  company,
  handleSetState
}) {
  return (
    <>
      <Modal
        title="Create New Account"
        isOpen={isOpen}
        text="CREATE"
        closeActions={closeActions}
        submitActions={submitActions}
        cancelText="Don't Save Data"
      >
        <div>
          <span>You must create an account in order to save data</span>
          <Textbox
            placeholder="First Name"
            fullWidth
            handleChange={(values) => handleSetState({ firstName: values })}
            currentValue={firstName}
          />
          <Textbox
            placeholder="Last Name"
            fullWidth
            handleChange={(values) => handleSetState({ lastName: values })}
            currentValue={lastName}
          />
          <Textbox
            placeholder="Company Name"
            fullWidth
            handleChange={(values) => handleSetState({ company: values })}
            currentValue={company}
          />
          <Textbox
            placeholder="Email"
            fullWidth
            handleChange={(values) => handleSetState({ email: values })}
            currentValue={email}
          />
          <Textbox
            placeholder="Password"
            type="password"
            fullWidth
            handleChange={(values) => handleSetState({ password: values })}
            currentValue={password}
          />
          <Textbox
            placeholder="Confirm Password"
            type="password"
            fullWidth
            handleChange={(values) =>
              handleSetState({ confirmPassword: values })
            }
            currentValue={confirmPassword}
          />
          <div className={styles.explationText}>or</div>
          <GoogleLogin
            clientId={config.googleClientId}
            buttonText="Sign In with Google"
            onSuccess={submitActions}
            onFailure={submitActions}
            cookiePolicy="single_host_origin"
            theme="dark"
          />
        </div>
      </Modal>
    </>
  );
}
