import React, { Component } from 'react';

import styles from './styles.module.scss';

export default class EvaluationModal extends Component {
  render() {
    const { isOpen, closeActions, messages } = this.props;

    const className = isOpen
      ? [styles.modal, styles.modalShow]
      : [styles.modal, styles.modalHide];

    const issueMessages = messages?.issueMessages;
    const infoMessages = messages?.infoMessages;
    const warningMessages = messages?.warningMessages;
    const criticalMessages = messages?.criticalMessages;

    return (
      <>
        <div className={className.join(' ')}>
          <div className={styles.modalBody}>
            <header>
              <div>
                <img
                  src={require('../../assets/images/evaluate.png')}
                  alt="Evaluation Icon"
                />{' '}
                MAP EVALUATION
              </div>
              <div className={styles.close} onClick={closeActions}>
                <div>X</div>
              </div>
            </header>
            <div>
              <h2>Information Messages</h2>
              {infoMessages?.length > 0 && (
                <ol className={styles.infoMessages}>
                  {infoMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ol>
              )}
              <h2>Warning Messages</h2>
              {warningMessages?.length > 0 && (
                <ol className={styles.warningMessages}>
                  {warningMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ol>
              )}
              <h2>Critical Messages</h2>
              {criticalMessages?.length > 0 && (
                <ol className={styles.criticalMessages}>
                  {criticalMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ol>
              )}
              <h2>Issue Messages</h2>
              {issueMessages?.length > 0 && (
                <ol className={styles.issueMessages}>
                  {issueMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ol>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
