import { call, select, put } from 'redux-saga/effects';
import history from '../history';
import {
  fetchMapsResponse,
  addMapResponse,
  updateMapResponse,
  deleteMapResponse,
  setActiveMap
} from '../actions/maps';
import { addMessage, removeMessage } from '../actions/messages';
import { exampleMap } from '../constants/example';
import { getMapListSelector } from '../selectors/maps';
import { isFreeUserSelector, getActiveUserSelector } from '../selectors/user';

export function* fetchMaps(api, { payload }) {
  try {
    let mapList;
    const isFreeUser = yield select(isFreeUserSelector);
    const activeUser = yield select(getActiveUserSelector);
    const allMaps = yield select(getMapListSelector);

    if (isFreeUser && allMaps.length > 0) {
      mapList = [...allMaps];
    } else if (isFreeUser) {
      mapList = [exampleMap];
    } else if (payload?.folderId) {
      mapList = yield call(api.fetchMapsByFolder, {
        userId: activeUser._id,
        folderId: payload.folderId
      });
    } else {
      mapList = yield call(api.fetchMaps, { userId: activeUser._id });
    }

    yield put(fetchMapsResponse(mapList));
  } catch (error) {
    console.log(error);
  }
}

export function* addMap(api, { payload }) {
  try {
    yield put(removeMessage());
    const isFreeUser = yield select(isFreeUserSelector);
    const activeUser = yield select(getActiveUserSelector);
    const mapList = yield select(getMapListSelector);

    if (isFreeUser && mapList.length === 2) {
      yield put(
        addMessage({
          error: true,
          message: 'Free trial cannot have more than 2 maps'
        })
      );
    } else if (isFreeUser) {
      yield put(addMapResponse({ ...payload, _id: 2 }));
      yield put(setActiveMap({ ...payload, _id: 2 }));
      history.push('/freeCardMapView');
    } else {
      const response = yield call(api.addMap, {
        ...payload,
        accountId: activeUser._id
      });

      yield put(addMapResponse(response));
      yield put(setActiveMap(response));
      history.push('/cardMapView');
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* updateMap(api, { payload }) {
  try {
    yield put(removeMessage());
    const isFreeUser = yield select(isFreeUserSelector);

    if (!isFreeUser) {
      const response = yield call(api.updateMap, payload);

      yield put(updateMapResponse(response));
    } else {
      yield put(updateMapResponse(payload));
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* deleteMap(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteMap, payload);

    yield put(deleteMapResponse(payload));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}
