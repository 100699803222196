import React from 'react';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import history from '../../history';
import AuthenticatedRoute from '../AuthenticatedRoute';
import routes from '../../constants/routes';
import styles from './styles.module.scss';

const options = {
  position: 'top center',
  timeout: 4000,
  offset: '30px',
  transition: 'scale',
  width: '100%'
};

const AlertTemplate = ({ options, message, close }) => (
  <div
    className={[
      styles.alertStyle,
      options.type === 'error'
        ? styles.alertStyleError
        : '' + options.type === 'success'
        ? styles.alertStyleSuccess
        : ''
    ].join(' ')}
  >
    <div className={styles.alertMessage}>{message}</div>
    <div onClick={close} className="modalBody-header--X">
      <img
        src={require('../../assets/images/modalX.png')}
        alt="close the alert"
      />
    </div>
  </div>
);

const App = ({ store }) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div id="app" className={styles.root}>
          <AlertProvider template={AlertTemplate} {...options}>
            <Switch>
              {routes
                .filter((route) => !route.authenticated)
                .map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    component={route.main}
                    exact={route.exact}
                  />
                ))}
              {routes
                .filter((route) => route.authenticated)
                .map((route, index) => (
                  <AuthenticatedRoute
                    key={index}
                    path={route.path}
                    component={route.main}
                    exact={route.exact}
                  />
                ))}
              <Redirect from="/" to="/login" />
            </Switch>
          </AlertProvider>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
