import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../../selectors/user';

export const AuthenticatedRoute = ({ isAuthenticated, ...routerProps }) => {
  return isAuthenticated ? (
    <Route {...routerProps} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { referrer: routerProps.location.pathname }
      }}
    />
  );
};

export default connect((state) => ({
  isAuthenticated: isAuthenticated(state)
}))(AuthenticatedRoute);
