import React from 'react';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default function Modal({
  title,
  isOpen,
  children,
  text,
  submitImage,
  closeActions,
  submitActions,
  cancelText
}) {
  const className = isOpen
    ? [styles.modal, styles.modalShow]
    : [styles.modal, styles.modalHide];

  return (
    <>
      <div className={className.join(' ')}>
        <div className={styles.modalBody}>
          <header>
            <div>{title}</div>
            <div className={styles.close} onClick={closeActions}>
              <div>X</div>
            </div>
          </header>
          <div>{children}</div>
          <footer>
            <Button
              color="gray-gradient"
              text={cancelText || 'CANCEL'}
              handleClick={closeActions}
            />
            <Button
              color="orange"
              showImage={submitImage}
              text={text}
              image={submitImage}
              handleClick={submitActions}
            />
          </footer>
        </div>
      </div>
    </>
  );
}
