import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { addUserRequest } from '../../actions/user';
import { getMessagesSelector } from '../../selectors/messages';
import Button from '../../components/inputs/Button';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

export class NewUserRegistration extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    company: '',
    triedToSubmit: false,
    passwordsNoMatch: false
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  handleSubmit() {
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      company
    } = this.state;
    if (
      !email ||
      !firstName ||
      !lastName ||
      !password ||
      !company ||
      !confirmPassword
    ) {
      this.setState({ triedToSubmit: true });
    } else if (password !== confirmPassword) {
      this.setState({ passwordsNoMatch: true });
    } else {
      this.props.addUser({
        ...this.state,
        referrer: this.props.location?.state?.referrer
      });
    }
  }

  render() {
    return (
      <>
        <header className={styles.topHeader} />
        <div className={styles.login}>
          <header>INFLUENCE MAPPER</header>
          <Textbox
            placeholder="First Name"
            fullWidth
            handleChange={(values) => this.setState({ firstName: values })}
          />
          <Textbox
            placeholder="Last Name"
            fullWidth
            handleChange={(values) => this.setState({ lastName: values })}
          />
          <Textbox
            placeholder="Company Name"
            fullWidth
            handleChange={(values) => this.setState({ company: values })}
          />
          <Textbox
            placeholder="Email"
            fullWidth
            handleChange={(values) => this.setState({ email: values })}
          />
          <Textbox
            placeholder="Password"
            type="password"
            fullWidth
            handleChange={(values) => this.setState({ password: values })}
          />
          <Textbox
            placeholder="Confirm Password"
            type="password"
            fullWidth
            handleChange={(values) =>
              this.setState({ confirmPassword: values })
            }
          />
          <Button
            text="Create Account"
            color="orange"
            showImage
            image={require('../../assets/images/SignIn.png')}
            handleClick={() => this.handleSubmit()}
            fullWidth
          />
          <div className={styles.bottomLinks}>
            <Link
              to={{
                pathname: '/login',
                state: { referrer: this.props.location?.state?.referrer }
              }}
            >
              Back to Login
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: getMessagesSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  addUser: (payload) => dispatch(addUserRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(NewUserRegistration)
);
