export const influenceMapper = (buyerPower, friendFoe) => {
  if (buyerPower < 0) {
    return { color: 'yellow', text: 'neutral' };
  } else if (friendFoe < -2) {
    return { color: 'red', text: 'negative' };
  } else if (buyerPower === 0) {
    if (friendFoe === -2) {
      return { color: 'red', text: 'negative' };
    } else {
      return { color: 'yellow', text: 'neutral' };
    }
  } else if (buyerPower === 1) {
    if (friendFoe < 3) {
      return { color: 'yellow', text: 'neutral' };
    } else {
      return { color: 'green', text: 'positive' };
    }
  } else {
    if (friendFoe < 1) {
      return { color: 'yellow', text: 'neutral' };
    } else {
      return { color: 'green', text: 'positive' };
    }
  }
};

export const foeFriendMapper = (friendFoe) => {
  switch (friendFoe) {
    case -4:
      return 'red';

    case -3:
      return 'red-dark';

    case -2:
      return 'red-medium';

    case -1:
      return 'red-light';

    case 0:
      return 'gray-background';

    case 1:
      return 'green-light';

    case 2:
      return 'green-medium';

    case 3:
      return 'green-dark';

    case 4:
      return 'green';

    default:
      return 'gray-background';
  }
};

export const buyerPowerMapper = (buyerPower) => {
  switch (buyerPower) {
    case 0:
      return 'gray-background';

    case 1:
      return 'green-light';

    case 2:
      return 'green-medium';

    case 3:
      return 'green-dark';

    case 4:
      return 'green';

    default:
      return 'gray-background';
  }
};

export const roleMapper = (role) => {
  switch (role) {
    case 0:
      return {
        img: require('../assets/images/questionMark.png'),
        header: 'UNKNOWN',
        text: `This person’s role is unknown in relation to this
        opportunity. Work to understand this person’s role. Any
        unknown Buyer Role is a risk to you.`
      };

    case 1:
      return {
        img: require('../assets/images/notInvolved.png'),
        header: 'NOT INVOLVED',
        text: `This person does not have a role in relation to this
        opportunity.`
      };

    case 2:
      return {
        img: require('../assets/images/gateKeeper.png'),
        header: 'GATEKEEPER',
        text: `This is a person who grants access to information or people
        within the organization.`
      };

    case 3:
      return {
        img: require('../assets/images/endUserBlack.png'),
        header: 'END USER',
        text: `The person who uses your product to do their job.`
      };

    case 4:
      return {
        img: require('../assets/images/influencer.png'),
        header: 'INFLUENCER',
        text: `This person has some level of input into the purchase
        decision.`
      };

    case 5:
      return {
        img: require('../assets/images/evaluator.png'),
        header: 'EVALUATOR',
        text: `This person will review your product for a technical fit or
        appropriateness of use.`
      };

    case 6:
      return {
        img: require('../assets/images/coach.png'),
        header: 'COACH',
        text: `A coach is your advocate. They provide timely
        and useful information to you. They are knowledgeable and
        respected within their organization. This person takes a
        personal interest in you and guides you through the selling
        process.`
      };

    case 7:
      return {
        img: require('../assets/images/spy.png'),
        header: 'SPY',
        text: `This person is working for your competitor and against you.
        They are the anit-COACH. Be mindful of all interactions with
        this person. What may seem to be kind gestures or
        invitations to draw closer are likely a ruse to gain
        advantage over you`
      };

    case 8:
      return {
        img: require('../assets/images/ownBudget.png'),
        header: 'OWNS BUDGET',
        text: `This person controls the budget for the project or purchase
        in question. This is the person with signature authority.`
      };

    case 9:
      return {
        img: require('../assets/images/wizard.png'),
        header: 'WIZARD',
        text: `This person has a disproportionate level of influence with
        the Ultimate Decision Maker (UDM) than what their title or
        position may suggest. The UDM takes their recommendation.
        Can be the hardest person to identify. Deals are hard to win
        without their support`
      };

    case 10:
      return {
        img: require('../assets/images/ultimateDM.png'),
        header: 'ULTIMATE DM',
        text: `This person holds the final decision. They are the one
        person who can say “Yes” when everyone else says “No”. They
        may abdicate their decisions to others within the
        organization and they look towards the Wizard for their
        recommendations.`
      };

    default:
      return {
        img: require('../assets/images/questionMark.png'),
        header: 'UNKNOWN',
        text: `This person’s role is unknown in relation to this
        opportunity. Work to understand this person’s role. Any
        unknown Buyer Role is a risk to you.`
      };
  }
};

export const typeMapper = (type) => {
  switch (type) {
    case 0:
      return {
        img: require('../assets/images/executive.png'),
        header: 'EXECUTIVE',
        text: `Viewing the opportunity from a company impact perspective.`
      };

    case 1:
      return {
        img: require('../assets/images/financial.png'),
        header: 'FINANCIAL',
        text: `Focused on the dollars, cents, ROI, and budgetary aspect.`
      };

    case 2:
      return {
        img: require('../assets/images/technical.png'),
        header: 'TECHNICAL',
        text: `Interested in the compatibility, sustainability, and integration
        of the solution.`
      };

    case 3:
      return {
        img: require('../assets/images/administrative.png'),
        header: 'ADMINISTRATIVE',
        text: `Legal issues, compliance, contracts, and the PO are central.`
      };

    case 4:
      return {
        img: require('../assets/images/lineOfBusiness.png'),
        header: 'LINE OF BUSINESS',
        text: `Focused on business value, effect on work day, ease of use`
      };

    default:
      return {
        img: require('../assets/images/executive.png'),
        header: 'EXECUTIVE',
        text: `Viewing the opportunity from a company impact perspective.`
      };
  }
};
