import {
  ADD_USERCARD_REQUEST,
  ADD_USERCARD_RESPONSE,
  UPDATE_USERCARD_REQUEST,
  UPDATE_USERCARD_RESPONSE,
  DELETE_USERCARD_REQUEST,
  DELETE_USERCARD_RESPONSE,
  FETCH_USERCARDS_REQUEST,
  FETCH_USERCARDS_RESPONSE,
  FETCH_MAP_AND_CARDS_REQUEST,
  SAVE_ALL_USERCARDS_REQUEST
} from '../constants/actionTypes';

export const fetchUserCardsRequest = (payload) => ({
  type: FETCH_USERCARDS_REQUEST,
  payload
});

export const fetchUserCardsResponse = (payload) => ({
  type: FETCH_USERCARDS_RESPONSE,
  payload
});

export const fetchMapAndCardsRequest = (payload) => ({
  type: FETCH_MAP_AND_CARDS_REQUEST,
  payload
});

export const addUserCardRequest = (payload) => ({
  type: ADD_USERCARD_REQUEST,
  payload
});

export const addUserCardResponse = (payload) => ({
  type: ADD_USERCARD_RESPONSE,
  payload
});

export const updateUserCardRequest = (payload) => ({
  type: UPDATE_USERCARD_REQUEST,
  payload
});

export const updateUserCardResponse = (payload) => ({
  type: UPDATE_USERCARD_RESPONSE,
  payload
});

export const deleteUserCardRequest = (payload) => ({
  type: DELETE_USERCARD_REQUEST,
  payload
});

export const deleteUserCardResponse = (payload) => ({
  type: DELETE_USERCARD_RESPONSE,
  payload
});

export const saveAllUserCardsRequest = (payload) => ({
  type: SAVE_ALL_USERCARDS_REQUEST,
  payload
});
