export const mapGridColor = (index) => {
  if (index > 44) {
    return 'strat-gray';
  } else if ([0, 1, 9, 10, 18, 19].includes(index)) {
    return 'strat-darkRed';
  } else if ([27, 28, 36, 37, 38].includes(index)) {
    return 'strat-lightRed';
  } else if (index > 38 && index < 45) {
    return 'strat-yellow';
  } else if ([29, 30, 31, 32, 33].includes(index)) {
    return 'strat-darkYellow';
  } else if ([2, 3, 4, 11, 12, 13, 20, 21, 22].includes(index)) {
    return 'strat-darkYellow';
  } else if ([34, 35].includes(index)) {
    return 'strat-lightGreen';
  } else if ([5, 14, 23, 24, 25, 26].includes(index)) {
    return 'strat-green';
  } else {
    return 'strat-darkGreen';
  }
};

export const mapUserToGrid = (index, userList) => {
  userList = userList.map((user) => {
    return {
      ...user,
      friendFoe: !user.friendFoe ? 0 : user.friendFoe,
      buyerPower: !user.buyerPower ? -1 : user.buyerPower
    };
  });
  switch (index) {
    case 0:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === -4
      );
    case 1:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === -3
      );
    case 2:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === -2
      );
    case 3:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === -1
      );
    case 4:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === 0
      );
    case 5:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === 1
      );
    case 6:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === 2
      );
    case 7:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === 3
      );
    case 8:
      return userList.filter(
        (user) => user.buyerPower === 4 && user.friendFoe === 4
      );
    case 9:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === -4
      );
    case 10:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === -3
      );
    case 11:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === -2
      );
    case 12:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === -1
      );
    case 13:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === 0
      );
    case 14:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === 1
      );
    case 15:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === 2
      );
    case 16:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === 3
      );
    case 17:
      return userList.filter(
        (user) => user.buyerPower === 3 && user.friendFoe === 4
      );
    case 18:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === -4
      );
    case 19:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === -3
      );
    case 20:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === -2
      );
    case 21:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === -1
      );
    case 22:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === 0
      );
    case 23:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === 1
      );
    case 24:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === 2
      );
    case 25:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === 3
      );
    case 26:
      return userList.filter(
        (user) => user.buyerPower === 2 && user.friendFoe === 4
      );
    case 27:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === -4
      );
    case 28:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === -3
      );
    case 29:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === -2
      );
    case 30:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === -1
      );
    case 31:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === 0
      );
    case 32:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === 1
      );
    case 33:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === 2
      );
    case 34:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === 3
      );
    case 35:
      return userList.filter(
        (user) => user.buyerPower === 1 && user.friendFoe === 4
      );
    case 36:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === -4
      );
    case 37:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === -3
      );
    case 38:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === -2
      );
    case 39:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === -1
      );
    case 40:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === 0
      );
    case 41:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === 1
      );
    case 42:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === 2
      );
    case 43:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === 3
      );
    case 44:
      return userList.filter(
        (user) => user.buyerPower === 0 && user.friendFoe === 4
      );
    case 45:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === -4
      );
    case 46:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === -3
      );
    case 47:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === -2
      );
    case 48:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === -1
      );
    case 49:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === 0
      );
    case 50:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === 1
      );
    case 51:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === 2
      );
    case 52:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === 3
      );
    case 53:
      return userList.filter(
        (user) => user.buyerPower === -1 && user.friendFoe === 4
      );
    default:
      return [];
  }
};

export const mapInformationalText = (index) => {
  switch (index) {
    case 0:
      return {
        header: 'MINIMIZE IMPACT (MANAGE RISK)',
        text: `Due to their power and negative disposition towards your company or solution, contacts in this area typically harmful to your cause,  If a UDM or Wizard is in this area, you’ll have great difficulty winning this deal.  Do your best to minimize impact that these contacts can have without alienating them.  They may try to win you over with faux kindness.  These are likely attempts at gaining information from you to leverage against you.`
      };

    case 2:
      return {
        header: 'PURSUADE & WIN (SELL)',
        text:
          'This area is where the real heavy lifting in sales comes in.  Contacts in this area need to be persuaded and you’ve got to change their outlook on your company or solution.  This area is where you can make the biggest difference in the decision-making process'
      };

    case 5:
      return {
        header: 'LEVERAGE & STRENGTHEN',
        text:
          'These contacts are fans and proponents of your solution.  Get to know who they know and how you can borrow their influence and advance their beliefs.'
      };

    case 6:
      return {
        header: 'MAXIMIZE & PARTNER',
        text:
          'Gently make it known that these executives and contacts are in your camp.  Try to use their gravity to draw others, not only inside this account, but with other potential clients through references and introductions.'
      };

    case 27:
      return {
        header: 'MINIMIZE CONTACT (POLITELY IGNORE)',
        text:
          'Contacts with little or no power, who don’t care for your solution are a drain on your time and resources.  Minimize contact and save your energy.  Be careful not to misread their influence or be dismissive as positions change over time.'
      };

    case 34:
      return {
        header: 'ENCOURAGE & PROMOTE',
        text:
          'Contacts in this area love your solution but may not have much say in the final decision.  Try to promote them and their view as you interact with others. Understand their relationships with others in the process and see if you can leverage those connections in a positive way.'
      };

    case 39:
      return {
        header: 'BE KIND & GRACIOUS',
        text:
          'Contacts in this area might be gatekeepers or administrative staff.  Always be pleasant, not just for the sake of winning business, just because it’s the right thing to do.'
      };

    case 45:
      return {
        header: 'MUST DETERMINE (ELIMINATE RISK)',
        text:
          'Any unknown quantity is a risk.  If you don’t know a person’s position or power, you can’t manage that element of the account.  You need to determine their values.  ON the battlefield, the most dangerous threats are the unknown threats.'
      };

    default:
      return '';
  }
};
