import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import history from '../../history';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default class MapHeader extends Component {
  state = {
    zoomLevel: 1,
    selectedMap: 'cardView',
    selectedCursor: 'default',
    selectedGrid: true
  };

  updateZoom = (isZoomIn) => {
    const { zoomLevel } = this.state;

    if (isZoomIn) {
      document.getElementById('mapView').style.zoom = zoomLevel + 0.1;
      this.setState({ zoomLevel: zoomLevel + 0.1 });
    } else {
      document.getElementById('mapView').style.zoom = zoomLevel - 0.1;
      this.setState({ zoomLevel: zoomLevel - 0.1 });
    }
  };

  fitAllOnScreen = () => {
    const elements = document.getElementsByClassName('styles_card__snUdt');

    const elementsLength = elements.length;

    const lastElementDomRect = elements[
      elementsLength - 1
    ].getBoundingClientRect();
    let isBelowAmount = window.innerHeight - lastElementDomRect.bottom;

    const isBelow = isBelowAmount < 0;

    if (isBelow) {
      isBelowAmount = Math.abs(isBelowAmount);

      const r = (window.innerHeight - isBelowAmount) / window.innerHeight;

      console.log(r);

      document.getElementById('mapView').style.zoom = r;
      this.setState({ zoomLevel: r });
    }
  };

  render() {
    const {
      openUserModal,
      showLegend,
      toggleGrid,
      toggleQuadView,
      evaluateMap,
      togglePan,
      handlePrint,
      openShareModal,
      disabled,
      isFreeUser,
      saveAllCards,
      openQuestionsModal,
      openEmailCoachModal
    } = this.props;

    const { selectedMap, selectedCursor, selectedGrid } = this.state;

    return (
      <header className={styles.mapNav}>
        <div className={styles.left}>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/dashboardWhite.png')}
            handleClick={() => {
              isFreeUser
                ? history.push('/freeDashboard')
                : history.push('/dashboard');
            }}
            text="Dashboard"
            displayTooltip
            name="dashboard"
          >
            <div>
              <h2>BACK TO DASHBOARD</h2>
              Go back to the dashboard screen where you can create new maps and
              folders to organize opportunities.
            </div>
          </Button>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/save.png')}
            handleClick={saveAllCards}
            noPadding
            displayTooltip
            name="save"
            disabled={disabled}
          >
            <div>
              <h2>SAVE MAP</h2>
              This will save the current map
            </div>
          </Button>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/evaluateWhite.png')}
            handleClick={evaluateMap}
            noPadding
            displayTooltip
            name="eval"
          >
            <div>
              <h2>MAP EVALUATION</h2>
              This will analyze your map in it’s current state. Evaluation will
              show:
              <ul>
                <li> Missing critical information </li>
                <li> Missing critical contacts</li>
                <li>Inconsistencies between roles, influence and power</li>
                <li>Threats to your success</li>
                <li>Suggestions concerning additional contacts </li>
                <li>Overall completeness of your map</li>
              </ul>
            </div>
          </Button>
          <div className={selectedMap === 'cardView' ? styles.selected : ''}>
            <Button
              color="black"
              showImage
              image={require('../../assets/images/mapIconWhite.png')}
              handleClick={() => {
                toggleQuadView(false);
                this.setState({ selectedMap: 'cardView' });
              }}
              noPadding
              displayTooltip
              name="treeView"
            >
              <div>
                <h2>TREE VIEW</h2>
                This is the default view. It allows you to create the influence
                map.
              </div>
            </Button>
          </div>
          <div className={selectedMap === 'quadView' ? styles.selected : ''}>
            <Button
              color="black"
              showImage
              image={require('../../assets/images/strategy.png')}
              handleClick={() => {
                toggleQuadView(true);
                this.setState({ selectedMap: 'quadView' });
              }}
              noPadding
              displayTooltip
              name="quadView"
            >
              <div>
                <h2>STRATEGY VIEW</h2>
                This view displays all of the individuals based upon the Buying
                Power and Friend/Foe ranking. FYI upper right-hand quadrant is
                good.
              </div>
            </Button>
          </div>
        </div>
        <div data-tip="support" data-for="support" className={styles.learn}>
          Learn & Support
        </div>
        <ReactTooltip
          className={styles.customTheme}
          place="bottom"
          effect="solid"
          type="light"
          id="support"
          delayHide={500}
        >
          <ul className={styles.dataList}>
            <li className={styles.disabled}>Getting Started</li>
            <li className={styles.disabled}>Selling Concepts</li>
            <li onClick={openQuestionsModal}>30 Questions to Help</li>
            <li onClick={openEmailCoachModal}>Contact a Sales Coach</li>
          </ul>
        </ReactTooltip>
        <div className={styles.middle}>
          <Button
            color="orange"
            showImage
            image={require('../../assets/images/endUserWhite.png')}
            handleClick={openUserModal}
            text="Add"
            displayTooltip
            name="addCard"
            disabled={selectedMap === 'quadView' || disabled}
          >
            <div>
              <h2>ADD A CARD</h2>
              Add users to expand the current map with this button.
            </div>
          </Button>
          <div className={selectedCursor === 'default' ? styles.selected : ''}>
            <Button
              color="black"
              showImage
              image={require('../../assets/images/selectArrow.png')}
              handleClick={() => {
                togglePan(false);
                this.setState({ selectedCursor: 'default' });
              }}
              noPadding
              displayTooltip
              name="selectArrow"
              disabled={selectedMap === 'quadView' || disabled}
            >
              <div>
                <h2>SELECTION</h2>
                This is the default tool. It allows you to select cards and move
                them around
              </div>
            </Button>
          </div>
          <div className={selectedCursor === 'pan' ? styles.selected : ''}>
            <Button
              color="black"
              showImage
              image={require('../../assets/images/hand.png')}
              handleClick={() => {
                togglePan(true);
                this.setState({ selectedCursor: 'pan' });
              }}
              noPadding
              displayTooltip
              name="panHand"
              disabled={selectedMap === 'quadView'}
            >
              <div>
                <h2>HAND</h2>
                This tool allows you to pan around the current map if it extends
                beyond your workspace.
              </div>
            </Button>
          </div>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/fitAll.png')}
            handleClick={() => this.fitAllOnScreen(20)}
            noPadding
            displayTooltip
            name="fitAll"
            disabled={selectedMap === 'quadView'}
          >
            <div>
              <h2>FIT ALL</h2>
              This tool will scale and position your entire map into the current
              window.
            </div>
          </Button>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/zoom.png')}
            handleClick={() => this.updateZoom(true)}
            noPadding
            displayTooltip
            name="zoomIn"
            disabled={selectedMap === 'quadView'}
          >
            <div>
              <h2>ZOOM IN</h2>
              Zoom into the current map.
            </div>
          </Button>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/unZoom.png')}
            handleClick={() => this.updateZoom(false)}
            noPadding
            displayTooltip
            name="zoomOut"
            disabled={selectedMap === 'quadView'}
          >
            <div>
              <h2>ZOOM OUT</h2>
              Zoom out of the current map.
            </div>
          </Button>
          <div className={selectedGrid ? styles.selected : ''}>
            <Button
              color="black"
              showImage
              image={require('../../assets/images/toggleGrid.png')}
              handleClick={() => {
                toggleGrid();
                this.setState({ selectedGrid: !selectedGrid });
              }}
              noPadding
              displayTooltip
              name="spacingGrid"
              disabled={selectedMap === 'quadView'}
            >
              <div>
                <h2>SPACING GRID</h2>
                Toggle on and off the visible grid.
              </div>
            </Button>
          </div>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/legendWhite.png')}
            handleClick={() => showLegend()}
            noPadding
            displayTooltip
            name="legend"
            disabled={selectedMap === 'quadView'}
          >
            <div>
              <h2>LEGEND</h2>
              View the buyer roles and types by clicking on the legend.
            </div>
          </Button>
        </div>
        <div className={styles.right}>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/printWhite.png')}
            handleClick={() => handlePrint()}
            noPadding
            displayTooltip
            name="printing"
            disabled={selectedMap === 'quadView' || disabled}
          >
            <div>
              <h2>PRINT</h2>
              Print the current map to a local printer or save it to a PDF.
            </div>
          </Button>
          <Button
            color="black"
            showImage
            image={require('../../assets/images/shareWhite.png')}
            handleClick={openShareModal}
            noPadding
            displayTooltip
            name="sharing"
            disabled={selectedMap === 'quadView' || disabled}
          >
            <div>
              <h2>SHARE</h2>
              Share the current map with other users, via a web link
            </div>
          </Button>
        </div>
      </header>
    );
  }
}
