import React from 'react';
import CardMapView from '../containers/CardMapView';
import Dashboard from '../containers/Dashboard';
import ForgotPassword from '../containers/ForgotPassword';
import Login from '../containers/Login';
import NewUserRegistration from '../containers/NewUserRegisteration';
import UnauthenticatedCardMapView from '../containers/UnauthenticatedCardMapView';
import UnauthenticatedDashboard from '../containers/UnauthenticatedDashboard';
import UserSettings from '../containers/UserSettings';

const routes = [
  {
    path: '/cardMapView/:mapId?',
    exact: true,
    authenticated: true,
    main: ({ match }) => <CardMapView match={match} />
  },
  {
    path: '/dashboard',
    exact: true,
    authenticated: true,
    main: ({ match }) => <Dashboard match={match} />
  },
  {
    path: '/forgotPassword',
    exact: true,
    main: ({ match }) => <ForgotPassword match={match} />
  },
  {
    path: '/login',
    exact: true,
    main: ({ match, location }) => <Login match={match} location={location} />
  },
  {
    path: '/newUserRegistration',
    exact: true,
    main: ({ match, location }) => (
      <NewUserRegistration match={match} location={location} />
    )
  },
  {
    path: '/settings',
    exact: true,
    authenticated: true,
    main: ({ match }) => <UserSettings match={match} />
  },
  {
    path: '/freeCardMapView/:mapId?',
    exact: true,
    main: ({ match }) => <UnauthenticatedCardMapView match={match} />
  },
  {
    path: '/freeDashboard',
    exact: true,
    main: ({ match }) => <UnauthenticatedDashboard match={match} />
  }
];

export default routes;
