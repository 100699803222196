import {
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  EMAIL_SALES_COACH_REQUEST
} from '../constants/actionTypes';

export const addMessage = (payload) => ({
  type: ADD_MESSAGE,
  payload
});

export const removeMessage = (payload) => ({
  type: REMOVE_MESSAGE,
  payload
});

export const emailSalesCoachRequest = (payload) => ({
  type: EMAIL_SALES_COACH_REQUEST,
  payload
});
