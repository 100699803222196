import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  UNKNOWN,
  NOT_INVOLVED,
  GATEKEEPER,
  END_USER,
  INFLUENCER,
  EVALUATOR,
  COACH,
  SPY,
  OWNS_BUDGET,
  WIZARD,
  ULTIMATE_DM,
  EXECUTIVE,
  FINANCIAL,
  TECHNICAL,
  ADMINISTRATIVE,
  LINE_OF_BUSINESS
} from '../../constants/constants';

import styles from './styles.module.scss';

export default function Legend({
  buyerRoles,
  buyerTypes,
  handleChange,
  noClick
}) {
  return (
    <>
      <div className={styles.legend}>
        <div className={styles.buyerRoles}>
          <header>Buyer Roles</header>
          <div>
            <div className={styles.column}>
              <div
                onClick={() => !noClick && handleChange({ roles: 0 })}
                className={buyerRoles.includes(UNKNOWN) ? styles.selected : ''}
                data-tip="unknown"
                data-for="unknown"
              >
                <img
                  src={require('../../assets/images/questionMark.png')}
                  alt="Select Unknown"
                />
                <div>UNKNOWN</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="unknown"
                >
                  <div>
                    <h2>UNKNOWN</h2>
                    This person’s role is unknown in relation to this
                    opportunity. Work to understand this person’s role. Any
                    unknown Buyer Role is a risk to you.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 1 })}
                className={
                  buyerRoles.includes(NOT_INVOLVED) ? styles.selected : ''
                }
                data-tip="not involved"
                data-for="not involved"
              >
                <img
                  src={require('../../assets/images/notInvolved.png')}
                  alt="Select Not Involved"
                />
                <div>NOT INVOLVED</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="not involved"
                >
                  <div>
                    <h2>NOT INVOLVED</h2>
                    This person does not have a role in relation to this
                    opportunity.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 2 })}
                className={
                  buyerRoles.includes(GATEKEEPER) ? styles.selected : ''
                }
                data-tip="gatekeeper"
                data-for="gatekeeper"
              >
                <img
                  src={require('../../assets/images/gateKeeper.png')}
                  alt="Select Executive"
                />
                <div>GATEKEEPER</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="gatekeeper"
                >
                  <div>
                    <h2>GATEKEEPER</h2>
                    This is a person who grants access to information or people
                    within the organization.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 3 })}
                className={buyerRoles.includes(END_USER) ? styles.selected : ''}
                data-tip="endUser"
                data-for="endUser"
              >
                <img
                  src={require('../../assets/images/endUserBlack.png')}
                  alt="Select Executive"
                />
                <div>END USER</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="endUser"
                >
                  <div>
                    <h2>END USER</h2>
                    The person who uses your product to do their job.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 4 })}
                className={
                  buyerRoles.includes(INFLUENCER) ? styles.selected : ''
                }
                data-tip="influencer"
                data-for="influencer"
              >
                <img
                  src={require('../../assets/images/influencer.png')}
                  alt="Select Executive"
                />
                <div>INFLUENCER</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="influencer"
                >
                  <div>
                    <h2>INFLUENCER</h2>
                    This person has some level of input into the purchase
                    decision.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 5 })}
                className={
                  buyerRoles.includes(EVALUATOR) ? styles.selected : ''
                }
                data-tip="evaluator"
                data-for="evaluator"
              >
                <img
                  src={require('../../assets/images/evaluator.png')}
                  alt="Select Executive"
                />
                <div>EVALUATOR</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="evaluator"
                >
                  <div>
                    <h2>EVALUATOR</h2>
                    This person will review your product for a technical fit or
                    appropriateness of use.
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className={styles.column}>
              <div
                onClick={() => !noClick && handleChange({ roles: 6 })}
                className={buyerRoles.includes(COACH) ? styles.selected : ''}
                data-tip="coach"
                data-for="coach"
              >
                <img
                  src={require('../../assets/images/coach.png')}
                  alt="Select Executive"
                />
                <div>COACH</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="coach"
                >
                  <div>
                    <h2>COACH</h2>A coach is your advocate. They provide timely
                    and useful information to you. They are knowledgeable and
                    respected within their organization. This person takes a
                    personal interest in you and guides you through the selling
                    process.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 7 })}
                className={buyerRoles.includes(SPY) ? styles.selected : ''}
                data-tip="spy"
                data-for="spy"
              >
                <img
                  src={require('../../assets/images/spy.png')}
                  alt="Select Executive"
                />
                <div>SPY</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="spy"
                >
                  <div>
                    <h2>SPY</h2>
                    This person is working for your competitor and against you.
                    They are the anit-COACH. Be mindful of all interactions with
                    this person. What may seem to be kind gestures or
                    invitations to draw closer are likely a ruse to gain
                    advantage over you
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 8 })}
                className={
                  buyerRoles.includes(OWNS_BUDGET) ? styles.selected : ''
                }
                data-tip="ownBudget"
                data-for="ownBudget"
              >
                <img
                  src={require('../../assets/images/ownBudget.png')}
                  alt="Select Executive"
                />
                <div>OWNS BUDGET</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="ownBudget"
                >
                  <div>
                    <h2>OWNS BUDGET</h2>
                    This person controls the budget for the project or purchase
                    in question. This is the person with signature authority.
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 9 })}
                className={buyerRoles.includes(WIZARD) ? styles.selected : ''}
                data-tip="wizard"
                data-for="wizard"
              >
                <img
                  src={require('../../assets/images/wizard.png')}
                  alt="Select Executive"
                />
                <div>WIZARD</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="wizard"
                >
                  <div>
                    <h2>WIZARD</h2>
                    This person has a disproportionate level of influence with
                    the Ultimate Decision Maker (UDM) than what their title or
                    position may suggest. The UDM takes their recommendation.
                    Can be the hardest person to identify. Deals are hard to win
                    without their support
                  </div>
                </ReactTooltip>
              </div>
              <div
                onClick={() => !noClick && handleChange({ roles: 10 })}
                className={
                  buyerRoles.includes(ULTIMATE_DM) ? styles.selected : ''
                }
                data-tip="ultimateDM"
                data-for="ultimateDM"
              >
                <img
                  src={require('../../assets/images/ultimateDM.png')}
                  alt="Select Executive"
                />
                <div>ULTIMATE DM</div>
                <ReactTooltip
                  className={styles.customTheme}
                  place="bottom"
                  effect="solid"
                  type="light"
                  id="ultimateDM"
                >
                  <div>
                    <h2>ULTIMATE DM</h2>
                    This person holds the final decision. They are the one
                    person who can say “Yes” when everyone else says “No”. They
                    may abdicate their decisions to others within the
                    organization and they look towards the Wizard for their
                    recommendations.
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buyerTypes}>
          <header>Buyer Types</header>
          <div
            onClick={() => !noClick && handleChange({ types: 0 })}
            className={buyerTypes.includes(EXECUTIVE) ? styles.selected : ''}
            data-tip="executive"
            data-for="executive"
          >
            <img
              src={require('../../assets/images/executive.png')}
              alt="Select Executive"
            />
            <div>EXECUTIVE</div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="executive"
            >
              <div>
                <h2>EXECUTIVE</h2>
                Viewing the opportunity from a company impact perspective.
              </div>
            </ReactTooltip>
          </div>
          <div
            onClick={() => !noClick && handleChange({ types: 1 })}
            className={buyerTypes.includes(FINANCIAL) ? styles.selected : ''}
            data-tip="financial"
            data-for="financial"
          >
            <img
              src={require('../../assets/images/financial.png')}
              alt="Select Financial"
            />
            <div>FINANCIAL</div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="financial"
            >
              <div>
                <h2>FINANCIAL</h2>
                Focused on the dollars, cents, ROI, and budgetary aspect.
              </div>
            </ReactTooltip>
          </div>
          <div
            onClick={() => !noClick && handleChange({ types: 2 })}
            className={buyerTypes.includes(TECHNICAL) ? styles.selected : ''}
            data-tip="technical"
            data-for="technical"
          >
            <img
              src={require('../../assets/images/technical.png')}
              alt="Select Technical"
            />
            <div>TECHNICAL</div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="technical"
            >
              <div>
                <h2>TECHNICAL</h2>
                Interested in the compatibility, sustainability, and integration
                of the solution.
              </div>
            </ReactTooltip>
          </div>
          <div
            onClick={() => !noClick && handleChange({ types: 3 })}
            className={
              buyerTypes.includes(ADMINISTRATIVE) ? styles.selected : ''
            }
            data-tip="admin"
            data-for="admin"
          >
            <img
              src={require('../../assets/images/administrative.png')}
              alt="Select Administrative"
            />
            <div>ADMINISTRATIVE</div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="admin"
            >
              <div>
                <h2>ADMINISTRATIVE</h2>
                Legal issues, compliance, contracts, and the PO are central.
              </div>
            </ReactTooltip>
          </div>
          <div
            onClick={() => !noClick && handleChange({ types: 4 })}
            className={
              buyerTypes.includes(LINE_OF_BUSINESS) ? styles.selected : ''
            }
            data-tip="lob"
            data-for="lob"
          >
            <img
              src={require('../../assets/images/lineOfBusiness.png')}
              alt="Select Line of Business"
            />
            <div>LINE OF BUSINESS</div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="lob"
            >
              <div>
                <h2>LINE OF BUSINESS</h2>
                Focused on business value, effect on work day, ease of use
              </div>
            </ReactTooltip>
          </div>
        </div>
      </div>
    </>
  );
}
