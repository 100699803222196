import React, { Component } from 'react';
import config from '../../config/config';
import Button from '../inputs/Button';
import Textbox from '../inputs/Textbox';

import styles from './styles.module.scss';

export default class ShareModal extends Component {
  state = {
    typeOfShareLink: 1,
    urlToShare: '',
    shareWhat: ''
  };

  getSharableUrl = (index) => {
    let url = `${config.webUrl}`;

    if (index === 2) {
      url = `${url}/freeCardMapView/${this.props.activeMap._id}`;
    } else {
      url = `${url}/cardMapView/${this.props.activeMap._id}`;
    }

    this.setState({ urlToShare: url, typeOfShareLink: index });
  };

  render() {
    const {
      isOpen,
      handleCopyMapToClipboard,
      handleCopyTextToClipboard,
      disabled,
      closeActions,
      isFromDashboard
    } = this.props;
    const { typeOfShareLink, urlToShare } = this.state;

    const className = isOpen
      ? [styles.modal, styles.modalShow]
      : [styles.modal, styles.modalHide];

    return (
      <>
        <div className={className.join(' ')}>
          <div className={styles.modalBody}>
            <div className={styles.shareClose} onClick={closeActions}>
              <div>X</div>
            </div>
            <header>INFLUENCE MAPPER</header>
            {typeOfShareLink === 1 ? (
              <div>
                <div className={styles.explationText}>
                  Maps cannot be saved or shared from the free trial version.
                </div>
                <Button
                  text="Get a sharable link"
                  color="gray-gradient"
                  handleClick={() => this.getSharableUrl(2)}
                  fullWidth
                  disabled={disabled}
                />
                <div className={styles.explationText}>
                  To paste into your CRM or email.
                </div>
                <hr />
                <Button
                  text="Share with a coworker"
                  color="gray-gradient"
                  handleClick={() => this.getSharableUrl(3)}
                  fullWidth
                  disabled={disabled}
                />
                <div className={styles.explationText}>
                  *Recipiant will need to create an id.
                </div>
                <hr />
                <Button
                  text="Copy Map to Clipboard"
                  color="orange"
                  handleClick={() => {
                    handleCopyMapToClipboard();
                    this.setState({
                      typeOfShareLink: 4,
                      shareWhat: 'viewable map'
                    });
                    setTimeout(
                      () => this.setState({ typeOfShareLink: 1 }),
                      2000
                    );
                  }}
                  fullWidth
                  disabled={isFromDashboard}
                />
                <div className={styles.explationText}>
                  A screenshot of the current map window will be created. Cannot
                  be copied from the dashboard
                </div>
              </div>
            ) : typeOfShareLink === 2 ? (
              <div>
                <div className={styles.explationText}>
                  This link is for a viewable map only, not editable.
                </div>
                <Textbox
                  placeholder="First and Last Name"
                  fullWidth
                  currentValue={urlToShare}
                  disabled={true}
                />
                <Button
                  text="Copy Link"
                  color="orange"
                  handleClick={() => {
                    handleCopyTextToClipboard(urlToShare);
                    this.setState({
                      typeOfShareLink: 4,
                      shareWhat: 'view only link'
                    });
                    setTimeout(
                      () => this.setState({ typeOfShareLink: 1 }),
                      2000
                    );
                  }}
                  fullWidth
                />
              </div>
            ) : typeOfShareLink === 3 ? (
              <div>
                <div className={styles.explationText}>
                  This link is for an editable map.
                </div>
                <div className={styles.explationText}>
                  This link is for an editable map.
                </div>
                *Recipiant will be asked to create an id.
                <Textbox
                  placeholder="First and Last Name"
                  fullWidth
                  currentValue={urlToShare}
                  disabled={true}
                />
                <Button
                  text="Copy Link"
                  color="orange"
                  handleClick={() => {
                    handleCopyTextToClipboard(urlToShare);
                    this.setState({
                      typeOfShareLink: 4,
                      shareWhat: 'view only link'
                    });
                    setTimeout(
                      () => this.setState({ typeOfShareLink: 1 }),
                      2000
                    );
                  }}
                  fullWidth
                />
              </div>
            ) : (
              <div>
                Current {this.state.shareWhat} has been copied to the clipboard.
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
