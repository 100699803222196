import React, { Component } from 'react';
import { FOE_FRIEND, BUYER_POWER } from '../../constants/constants';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import FileUpload from '../inputs/FileUpload';
import Legend from '../Legend';
import Textarea from '../inputs/Textarea';
import Textbox from '../inputs/Textbox';

import styles from './styles.module.scss';

export default class NewUserModal extends Component {
  state = {
    openNotes: false,
    name: '',
    jobTitle: '',
    image: '',
    influence: '',
    notes: {
      strategy: '',
      comment: ''
    },
    keyBuyer: false,
    roles: [],
    types: [],
    friendFoe: null,
    buyerPower: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.userCard && this.props.userCard !== prevProps.userCard) {
      if (!this.props.userCard.name) this.resetState({});
      else this.setState({ ...this.props.userCard });
    }
  }

  resetState = () => {
    this.setState({
      openNotes: false,
      name: '',
      jobTitle: '',
      image: '',
      influence: '',
      notes: {
        strategy: '',
        comment: ''
      },
      keyBuyer: false,
      roles: [],
      types: [],
      friendFoe: null,
      buyerPower: null
    });
  };

  handleLegendChange = (values) => {
    const { roles, types } = this.state;

    if (parseInt(values.roles, 10) >= 0) {
      const roleIndex = roles.findIndex((role) => role === values.roles);

      if (roleIndex > -1) {
        let temp = [
          ...roles.slice(0, roleIndex),
          ...roles.slice(roleIndex + 1)
        ];

        this.setState({ roles: temp });
      } else {
        if (roles.length === 3) {
          alert('You cannot have more than 3 roles');
          return;
        }

        this.setState({ roles: [...roles, values.roles] });
      }
    } else {
      const typeIndex = types.findIndex((type) => type === values.types);

      if (typeIndex > -1) {
        let temp = [
          ...types.slice(0, typeIndex),
          ...types.slice(typeIndex + 1)
        ];

        this.setState({ types: temp });
      } else {
        if (types.length === 3) {
          alert('You cannot have more than 3 types');
          return;
        }
        this.setState({ types: [...types, values.types] });
      }
    }
  };

  handleImageChange = (value) => {
    this.setState({ image: value });
  };

  handleSubmit = () => {
    this.props.submitActions(this.state);
    this.resetState();
  };

  render() {
    const { isOpen, closeActions } = this.props;
    const {
      name,
      jobTitle,
      notes,
      roles,
      types,
      friendFoe,
      buyerPower,
      isEditing,
      image
    } = this.state;

    const className = isOpen
      ? [styles.modal, styles.modalShow]
      : [styles.modal, styles.modalHide];

    return (
      <>
        <div className={className.join(' ')}>
          <div className={styles.modalBody}>
            <header>
              <div>
                <img
                  src={require('../../assets/images/endUserWhite.png')}
                  alt="User Icon"
                />{' '}
                {`${isEditing ? 'EDITING' : 'CREATE'} USER PROFILE`}
              </div>
              <div className={styles.close} onClick={closeActions}>
                <div>X</div>
              </div>
            </header>
            <div>
              <div className={styles.mainContent}>
                <div className={styles.userInfo}>
                  <div>
                    <Textbox
                      label="Name"
                      placeholder="First and Last Name"
                      fullWidth
                      currentValue={name}
                      handleChange={(value) => this.setState({ name: value })}
                      required
                    />
                    <Textbox
                      label="Title"
                      placeholder="Company Title"
                      fullWidth
                      currentValue={jobTitle}
                      handleChange={(value) =>
                        this.setState({ jobTitle: value })
                      }
                    />
                    <Checkbox
                      label="Foe / Friend"
                      options={FOE_FRIEND}
                      currentValue={friendFoe}
                      handleChange={(value) =>
                        this.setState({ friendFoe: value })
                      }
                    />
                    <Checkbox
                      label="Buyer Power"
                      options={BUYER_POWER}
                      currentValue={buyerPower}
                      handleChange={(value) =>
                        this.setState({ buyerPower: value })
                      }
                    />
                  </div>
                  <div>
                    <FileUpload
                      propImage={image}
                      handleChange={(value) => this.handleImageChange(value)}
                    />
                  </div>
                </div>
                <div className={styles.buyerInfo}>
                  <Legend
                    buyerRoles={roles}
                    buyerTypes={types}
                    handleChange={(values) => this.handleLegendChange(values)}
                  />
                </div>
              </div>
              <div className={styles.notes}>
                <header>NOTES</header>
                <Textarea
                  label="Strategy"
                  placeholder="Notes about strategy goes in this input box."
                  fullWidth
                  currentValue={notes.strategy}
                  handleChange={(value) =>
                    this.setState({ notes: { ...notes, strategy: value } })
                  }
                />
                <Textarea
                  label="Comments"
                  placeholder="Any additional comments about the individual goes here."
                  fullWidth
                  currentValue={notes.comment}
                  handleChange={(value) =>
                    this.setState({ notes: { ...notes, comment: value } })
                  }
                />
              </div>
            </div>
            <footer>
              <Button
                color="gray-gradient"
                text="CANCEL"
                handleClick={closeActions}
              />
              <Button
                color="orange"
                text="Save"
                disabled={!this.state.name}
                handleClick={() => this.handleSubmit()}
              />
            </footer>
          </div>
        </div>
      </>
    );
  }
}
