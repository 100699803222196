import {
  ADD_FOLDER_REQUEST,
  ADD_FOLDER_RESPONSE,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_RESPONSE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_RESPONSE,
  FETCH_FOLDERS_REQUEST,
  FETCH_FOLDERS_RESPONSE,
  SET_ACTIVE_FOLDER
} from '../constants/actionTypes';

export const fetchFoldersRequest = (payload) => ({
  type: FETCH_FOLDERS_REQUEST,
  payload
});

export const fetchFoldersResponse = (payload) => ({
  type: FETCH_FOLDERS_RESPONSE,
  payload
});

export const addFolderRequest = (payload) => ({
  type: ADD_FOLDER_REQUEST,
  payload
});

export const addFolderResponse = (payload) => ({
  type: ADD_FOLDER_RESPONSE,
  payload
});

export const updateFolderRequest = (payload) => ({
  type: UPDATE_FOLDER_REQUEST,
  payload
});

export const updateFolderResponse = (payload) => ({
  type: UPDATE_FOLDER_RESPONSE,
  payload
});

export const deleteFolderRequest = (payload) => ({
  type: DELETE_FOLDER_REQUEST,
  payload
});

export const deleteFolderResponse = (payload) => ({
  type: DELETE_FOLDER_RESPONSE,
  payload
});

export const setActiveFolder = (payload) => ({
  type: SET_ACTIVE_FOLDER,
  payload
});
