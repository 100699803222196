import React from 'react';
import Modal from './index';

export default function DeleteUserModal({
  isOpen,
  closeActions,
  submitActions,
  username
}) {
  return (
    <>
      <Modal
        title="Delete User Card"
        isOpen={isOpen}
        text="DELETE"
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          Are you sure you want to delete {username}? This action is permanent.
          You will be able to create a new card for this person again.
        </div>
      </Modal>
    </>
  );
}
