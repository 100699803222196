import {
  ADD_USER_REQUEST,
  ADD_USER_RESPONSE,
  ADD_USER_AND_MAP_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  FREE_USER_REQUEST,
  LOG_IN,
  LOG_OUT,
  LOGIN_GOOGLE,
  FORGOT_PASSWORD_REQUEST
} from '../constants/actionTypes';
import history from '../history';

const initialState = {
  loading: false,
  isFreeUser: false,
  activeUser: {},
  authenticated: false
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOG_IN:
    case LOGIN_GOOGLE:
    case ADD_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case ADD_USER_AND_MAP_REQUEST:
      return { ...state, loading: true };

    case FREE_USER_REQUEST:
      return { ...state, isFreeUser: true, authenticated: false };

    case ADD_USER_RESPONSE:
      return {
        ...state,
        activeUser: payload,
        authenticated: true,
        isFreeUser: false
      };

    case LOG_OUT:
      localStorage.removeItem('token');
      history.push('/');
      return { ...state, activeUser: {}, authenticated: false };

    default:
      return { ...state };
  }
};

export default user;
