import React from 'react';
import Modal from './index';
import Textbox from '../inputs/Textbox';

export default function ChangePasswordModal({
  isOpen,
  closeActions,
  submitActions,
  password,
  confirmPassword,
  updatePassword
}) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        title="Change Password"
        text="Change Password"
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          <Textbox
            label="Password"
            type="password"
            fullWidth
            currentValue={password}
            handleChange={(values) => updatePassword({ password: values })}
          />
          <Textbox
            label="Confirm Password"
            type="password"
            fullWidth
            currentValue={confirmPassword}
            handleChange={(values) =>
              updatePassword({ confirmPassword: values })
            }
          />
        </div>
      </Modal>
    </>
  );
}
