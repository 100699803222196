import React from 'react';

import styles from './styles.module.scss';

export default function Textarea({
  label,
  placeholder,
  currentValue,
  fullWidth,
  handleChange,
  notesSection
}) {
  const classes = fullWidth
    ? [styles.textarea, styles.fullWidth]
    : notesSection
    ? [styles.textarea, styles.notesSection]
    : [styles.textarea];
  return (
    <>
      <div className={classes.join(' ')}>
        {label && <label htmlFor="textarea">{label}</label>}
        <textarea
          value={currentValue}
          placeholder={placeholder || 'Type here...'}
          id="textarea"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </>
  );
}
