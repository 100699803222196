import React, { Component } from 'react';
import Textbox from '../inputs/Textbox';
import Modal from './index';

import styles from './styles.module.scss';

export default class NewMapModal extends Component {
  state = {
    name: ''
  };

  handleSubmit = () => {
    this.props.submitActions(this.state);
    this.setState({ name: '' });
  };

  render() {
    const { isOpen, closeActions } = this.props;
    const { name } = this.state;

    return (
      <>
        <Modal
          title="Create Map"
          isOpen={isOpen}
          text="Create Map"
          submitImage={require('../../assets/images/mapIconWhite.png')}
          closeActions={closeActions}
          submitActions={() => this.handleSubmit()}
        >
          <div>
            <div className={styles.row}>
              <Textbox
                currentValue={name}
                label="Map Name"
                placeholder="New Map"
                handleChange={(value) => this.setState({ name: value })}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
