import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { MapInteractionCSS } from 'react-map-interaction';
import LineTo from 'react-lineto';
import print from 'print-js';
import html2canvas from 'html2canvas';
import { linkCardsRequest, unlinkCardsRequest } from '../../actions/lines';
import { emailSalesCoachRequest } from '../../actions/messages';
import {
  addUserCardRequest,
  updateUserCardRequest,
  deleteUserCardRequest,
  fetchUserCardsRequest,
  saveAllUserCardsRequest,
  fetchMapAndCardsRequest
} from '../../actions/userCards';
import { getActiveUserSelector } from '../../selectors/user';
import { getLinesListSelector } from '../../selectors/lines';
import { getActiveMapSelector } from '../../selectors/maps';
import { getMessagesSelector } from '../../selectors/messages';
import { getUserCardListSelector } from '../../selectors/userCards';
import { evaluate } from '../../utils/evaulateMap';
import DeleteUserModal from '../../components/Modal/deleteUserCardModal';
import EmailSalesCoachModal from '../../components/Modal/emailSalesCoachModal';
import EvaluationModal from '../../components/EvaluationModal';
import Get30QuestionsModal from '../../components/Get30QuestionsModal';
import LegendModal from '../../components/LegendModal';
import LinkCardModal from '../../components/Modal/linkCardModal';
import MapHeader from '../../components/MapHeader';
import NewUserModal from '../../components/NewUserModal';
import ShareModal from '../../components/ShareModal';
import QuadView from '../../components/QuadView';
import UnlinkCardModal from '../../components/Modal/unlinkCardModal';
import UserCard from '../../components/UserCard';

import styles from './styles.module.scss';

class CardMapView extends Component {
  state = {
    openNewUserModal: false,
    openLegendModal: false,
    openDeleteUserModal: false,
    isEditing: false,
    userCard: {},
    hideGrid: false,
    showQuadView: false,
    evaluationMessages: {},
    openEvaluationModal: false,
    panMap: false,
    openShareModal: false,
    userCards: [],
    openLinkingModal: false,
    openUnlinkingModal: false,
    open30QuestionsModal: false,
    openEmailSalesCoachModal: false,
    socket: '',
    lockedUser: '',
    dirty: false
  };

  componentDidMount() {
    if (this.props.match.params.mapId) {
      this.props.fetchMapAndCards({ _id: this.props.match.params.mapId });
      this.setState({ disabled: true });
    } else {
      this.props.fetchUserCards();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (this.props.userCards && prevProps.userCards !== this.props.userCards) {
      this.setState({ userCards: this.props.userCards });
    }
  }

  handleSubmit = (values) => {
    if (this.state.isEditing) {
      this.props.updateUserCard(values);
    } else {
      this.props.addUserCard(values);
    }

    this.setState({ openNewUserModal: false, isEditing: false });
  };

  getClasses = () => {
    const { hideGrid, showQuadView } = this.state;

    let classes = [styles.cardMapView];

    if (hideGrid && !showQuadView) {
      classes.push(styles.noGrid);
    }

    if (showQuadView) {
      classes = [styles.gridView];
    }

    return classes.join(' ');
  };

  getInsideClasses = () => {
    const { hideGrid } = this.state;
    let classes = [styles.cardMapViewBody];

    if (hideGrid) {
      classes.push(styles.noGrid);
    }

    return classes.join(' ');
  };

  deleteUser = () => {
    this.props.deleteUserCard(this.state.userCard);

    this.setState({ openDeleteUserModal: false, userCard: {} });
  };

  evaluateMap = async () => {
    const messages = await evaluate(this.props.userCards);
    this.setState({
      evaluationMessages: messages,
      openEvaluationModal: true
    });
  };

  updateUserCardPosition = (userCard) => {
    const userCards = this.state.userCards;
    const userCardIndex = userCards.findIndex(
      (card) => card._id === userCard._id
    );

    console.log('updateUserCardPosition');

    userCards[userCardIndex] = userCard;

    this.setState({ userCards });
  };

  saveAllCards = () => {
    this.props.saveAllUserCards(this.state.userCards);
  };

  handlePrint = () => {
    html2canvas(document.querySelector('#cardMapViewBody')).then((canvas) => {
      var myImage = canvas.toDataURL();

      print(myImage, 'image');
    });
  };

  handleCopyTextToClipboard = (url) => {
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setTimeout(() => this.setState({ openShareModal: false }), 2000);
  };

  handleCopyMapToClipboard = () => {
    html2canvas(document.querySelector('#mapView'))
      .then((canvas) => {
        var imgs = document.createElement('img');

        imgs.src = canvas.toDataURL();
        var bodys = document.body;
        bodys.appendChild(imgs);

        if (document.createRange) {
          var myrange = document.createRange();
          myrange.setStartBefore(imgs);
          myrange.setEndAfter(imgs);
          myrange.selectNode(imgs);
        } else {
          alert('CreateRange NOT work');
        }

        var sel = window.getSelection();
        sel.addRange(myrange);

        document.execCommand('copy');
        bodys.removeChild(imgs);
      })
      .catch((error) => console.error(error));
    setTimeout(() => this.setState({ openShareModal: false }), 2000);
  };

  render() {
    const {
      userCards,
      updateUserCard,
      addUserCard,
      activeMap,
      lines,
      linkCard,
      unlinkCard,
      emailSalesCoach
    } = this.props;
    const {
      openNewUserModal,
      openDeleteUserModal,
      isEditing,
      userCard,
      openLegendModal,
      showQuadView,
      hideGrid,
      evaluationMessages,
      openEvaluationModal,
      panMap,
      openShareModal,
      openLinkingModal,
      openUnlinkingModal,
      open30QuestionsModal,
      openEmailSalesCoachModal
    } = this.state;

    return (
      <>
        <div className={this.getClasses()}>
          <MapHeader
            openUserModal={() =>
              this.setState({ openNewUserModal: true, userCard: {} })
            }
            showLegend={() => this.setState({ openLegendModal: true })}
            toggleGrid={() => this.setState({ hideGrid: !hideGrid })}
            toggleQuadView={(value) => this.setState({ showQuadView: value })}
            evaluateMap={() => this.evaluateMap()}
            togglePan={(value) => this.setState({ panMap: value })}
            handlePrint={this.handlePrint}
            openShareModal={() => this.setState({ openShareModal: true })}
            activeMap={activeMap}
            saveAllCards={() => this.saveAllCards()}
            openQuestionsModal={() =>
              this.setState({ open30QuestionsModal: true })
            }
            openEmailCoachModal={() =>
              this.setState({ openEmailSalesCoachModal: true })
            }
          />
          <h2>{activeMap.name}</h2>
          {!showQuadView ? (
            <div id="cardMapViewBody" className={styles.cardMapViewBody}>
              <MapInteractionCSS
                disablePan={!panMap}
                disableZoom={true}
                className={panMap ? styles.pointer : styles.panHand}
              >
                <div id="mapView" className={styles.mapView}>
                  {userCards.map((userCard, index) => (
                    <UserCard
                      key={index}
                      index={index}
                      userCard={userCard}
                      updateUserCard={(values) => {
                        updateUserCard(values);
                      }}
                      updateUser={(values) =>
                        this.setState({
                          openNewUserModal: true,
                          userCard: values,
                          isEditing: true
                        })
                      }
                      handleDeleteUser={() =>
                        this.setState({
                          openDeleteUserModal: true,
                          userCard
                        })
                      }
                      duplicateCard={() => {
                        addUserCard({ ...userCard });
                      }}
                      updateUserCardPosition={(card) =>
                        this.updateUserCardPosition(card)
                      }
                      openLinkModal={() =>
                        this.setState({
                          openLinkingModal: true,
                          userCard: userCard
                        })
                      }
                      openUnlinkModal={() =>
                        this.setState({
                          openUnlinkingModal: true,
                          userCard: userCard
                        })
                      }
                    />
                  ))}
                  {lines &&
                    lines?.map((line, index) => (
                      <LineTo
                        from={`userCard_${line.from}`}
                        to={`userCard_${line.to}`}
                        borderWidth={5}
                        borderColor="#ffa200"
                        fromAnchor="center center"
                        toAnchor="center center"
                        key={index}
                        zIndex={-1}
                        within="styles_mapView__1Fg8M"
                        delay={0}
                      />
                    ))}
                </div>
              </MapInteractionCSS>
            </div>
          ) : (
            <QuadView userCards={userCards} />
          )}
        </div>
        <NewUserModal
          isOpen={openNewUserModal}
          isEditing={isEditing}
          userCard={userCard}
          closeActions={() => this.setState({ openNewUserModal: false })}
          submitActions={(values) => this.handleSubmit(values)}
        />
        <LegendModal
          isOpen={openLegendModal}
          closeActions={() => this.setState({ openLegendModal: false })}
        />
        <EvaluationModal
          isOpen={openEvaluationModal}
          closeActions={() => this.setState({ openEvaluationModal: false })}
          messages={evaluationMessages}
        />
        <DeleteUserModal
          isOpen={openDeleteUserModal}
          username={this.state.userCard.name}
          submitActions={() => this.deleteUser()}
          closeActions={() => this.setState({ openDeleteUserModal: false })}
        />
        <LinkCardModal
          isOpen={openLinkingModal}
          currentCard={userCard}
          userCards={userCards}
          submitActions={(values) => {
            linkCard(values);
            this.setState({ openLinkingModal: false, userCard: {} });
          }}
          closeActions={() => this.setState({ openLinkingModal: false })}
          lines={lines}
        />
        <UnlinkCardModal
          isOpen={openUnlinkingModal}
          currentCard={userCard}
          userCards={userCards}
          submitActions={(values) => {
            unlinkCard(values);
            this.setState({ openUnlinkingModal: false, userCard: {} });
          }}
          closeActions={() => this.setState({ openUnlinkingModal: false })}
          lines={lines}
        />
        <ShareModal
          isOpen={openShareModal}
          handleCopyMapToClipboard={this.handleCopyMapToClipboard}
          handleCopyTextToClipboard={(values) =>
            this.handleCopyTextToClipboard(values)
          }
          activeMap={activeMap}
          closeActions={() => this.setState({ openShareModal: false })}
        />
        <Get30QuestionsModal
          isOpen={open30QuestionsModal}
          closeActions={() => this.setState({ open30QuestionsModal: false })}
        />
        <EmailSalesCoachModal
          isOpen={openEmailSalesCoachModal}
          closeActions={() =>
            this.setState({ openEmailSalesCoachModal: false })
          }
          submitActions={(values) => {
            emailSalesCoach(values);
            this.setState({ openEmailSalesCoachModal: false });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userCards: getUserCardListSelector(state),
  message: getMessagesSelector(state),
  activeMap: getActiveMapSelector(state),
  lines: getLinesListSelector(state),
  activeUser: getActiveUserSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  addUserCard: (payload) => dispatch(addUserCardRequest(payload)),
  updateUserCard: (payload) => dispatch(updateUserCardRequest(payload)),
  deleteUserCard: (payload) => dispatch(deleteUserCardRequest(payload)),
  fetchUserCards: () => dispatch(fetchUserCardsRequest()),
  saveAllUserCards: (payload) => dispatch(saveAllUserCardsRequest(payload)),
  fetchMapAndCards: (payload) => dispatch(fetchMapAndCardsRequest(payload)),
  linkCard: (payload) => dispatch(linkCardsRequest(payload)),
  unlinkCard: (payload) => dispatch(unlinkCardsRequest(payload)),
  emailSalesCoach: (payload) => dispatch(emailSalesCoachRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(CardMapView)
);
