import React from 'react';
import styles from './styles.module.scss';

const Switch = ({ isOn, handleToggle, index }) => {
  return (
    <>
      <input
        className={styles.reactSwitchCheckbox}
        id={`react-switch-new_${index}`}
        type="checkbox"
        checked={isOn}
        onChange={handleToggle}
      />
      <label
        className={styles.reactSwitchLabel}
        htmlFor={`react-switch-new_${index}`}
      >
        <span className={styles.reactSwitchButton} />
      </label>
    </>
  );
};

export default Switch;
