import React from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import config from '../../config/config';
import Button from '../inputs/Button';

import './index.css';

export default function Get30QuestionsModal({ isOpen, closeActions }) {
  return (
    <ReactModal
      initWidth={560}
      onFocus={() => console.log('Modal is clicked')}
      isOpen={isOpen}
    >
      <header className="questionsHeader">
        <div>30 QUESTIONS TO HELP</div>
        <div className="close">
          <Button
            color="orange"
            text="Pop Out"
            handleClick={() =>
              window.open(`${config.apiUrl}/get30Questions`, '_blank')
            }
          />
          <Button color="orange" text="Close" handleClick={closeActions} />
        </div>
      </header>
      <div className="docContainer">
        <iframe
          title="pdfSummary"
          src={`${config.apiUrl}/get30Questions`}
          frameBorder="0"
          className="docViewer"
        />
      </div>
    </ReactModal>
  );
}
