import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import history from '../../history';
import {
  addFolderRequest,
  updateFolderRequest,
  deleteFolderRequest,
  fetchFoldersRequest,
  setActiveFolder
} from '../../actions/folders';
import {
  addMapRequest,
  updateMapRequest,
  deleteMapRequest,
  fetchMapsRequest,
  setActiveMap
} from '../../actions/maps';
import { emailSalesCoachRequest } from '../../actions/messages';
import { logout } from '../../actions/user';
import {
  getFolderListSelector,
  getActiveFolderSelector
} from '../../selectors/folders';
import { getMapListSelector } from '../../selectors/maps';
import { getMessagesSelector } from '../../selectors/messages';
import {
  isFreeUserSelector,
  getActiveUserSelector
} from '../../selectors/user';
import Button from '../../components/inputs/Button';
import ChangeFolderColorModal from '../../components/Modal/changeFolderColorModal';
import ColorPickerModal from '../../components/Modal/colorPickerModal';
import CreateFolderModal from '../../components/Modal/createFolderModal';
import DashboardHeader from '../../components/DashboardHeader';
import DeleteFolderModal from '../../components/Modal/deleteFolderModal';
import DeleteMapModal from '../../components/Modal/deleteMapModal';
import EditMapModal from '../../components/Modal/editMapModal';
import EmailSalesCoachModal from '../../components/Modal/emailSalesCoachModal';
import FolderCard from '../../components/FolderCard';
import Get30QuestionsModal from '../../components/Get30QuestionsModal';
import MapCard from '../../components/MapCard';
import MoveToFolderModal from '../../components/Modal/moveToFolderModal';
import NewMapModal from '../../components/Modal/newMapModal';
import RenameFolderModal from '../../components/Modal/renameFolderModal';
import ShareModal from '../../components/ShareModal';
import SortDropdown from '../../components/inputs/SortDropDown';
import Textbox from '../../components/inputs/Textbox';

import styles from './styles.module.scss';

class Dashboard extends Component {
  state = {
    openCreateFolderModal: false,
    openChangeFolderColorModal: false,
    openColorPicker: false,
    openNewMapModal: false,
    openMoveToFolderModal: false,
    openEditMapNameModal: false,
    openDeleteMapModal: false,
    openRenameFolderModal: false,
    openDeleteFolderModal: false,
    openEmailSalesCoachModal: false,
    map: {},
    folderColor: '',
    folder: {},
    maps: [],
    open30QuestionsModal: false,
    openShareModal: false
  };

  componentDidMount() {
    this.props.fetchMaps();

    if (!this.props.isFreeUser) {
      this.props.fetchFolders();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (this.props.maps && prevProps.maps !== this.props.maps) {
      this.setState({ maps: this.props.maps });
    }
  }

  filterMaps(searchText) {
    let maps = [];
    if (searchText) {
      maps = this.state.maps.filter((map) =>
        map.name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      maps = this.props.maps;
    }

    this.setState({ maps });
  }

  sortMaps(option) {
    let maps = this.state.maps;

    if (option.text === 'Date Created') {
      maps = maps.sort((a, b) => {
        if (a.createdAt === b.createdAt) {
          return 0;
        }
        return a.createdAt > b.createdAt ? 1 : -1;
      });
    } else if (option.text === 'Alphabetical') {
      maps = maps.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      });
    } else if (option.text === 'Last Modified') {
      maps = maps.sort((a, b) => {
        if (a.updatedAt === b.updatedAt) {
          return 0;
        }
        return b.updatedAt > a.updatedAt ? 1 : -1;
      });
    }

    this.setState({ maps });
  }

  handleCopyTextToClipboard = (url) => {
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setTimeout(() => this.setState({ openShareModal: false }), 2000);
  };

  render() {
    const {
      openCreateFolderModal,
      openChangeFolderColorModal,
      openColorPicker,
      openNewMapModal,
      openMoveToFolderModal,
      openEditMapNameModal,
      openDeleteMapModal,
      folderColor,
      folder,
      openRenameFolderModal,
      openDeleteFolderModal,
      openEmailSalesCoachModal,
      maps,
      open30QuestionsModal,
      openShareModal,
      map
    } = this.state;

    const {
      addMap,
      updateMap,
      deleteMap,
      folders,
      setActiveMap,
      addFolder,
      updateFolder,
      deleteFolder,
      setActiveFolder,
      activeFolder,
      isFreeUser,
      logout,
      fetchMaps,
      emailSalesCoach,
      activeUser
    } = this.props;

    const breadCrumbs = activeFolder
      ? [
          <div onClick={() => fetchMaps() && setActiveFolder()} key={0}>
            All Maps
          </div>,
          <div key={1}> {` > `} </div>,
          <div key={2}>{activeFolder.name}</div>
        ]
      : [
          <div onClick={() => fetchMaps()} key={0}>
            All Maps
          </div>
        ];

    return (
      <>
        <div className={styles.dashboard}>
          <DashboardHeader
            logout={logout}
            openEmailCoachModal={() =>
              this.setState({ openEmailSalesCoachModal: true })
            }
            username={`${activeUser.firstName} ${activeUser.lastName}`}
            openQuestionsModal={() =>
              this.setState({ open30QuestionsModal: true })
            }
          />
          <header className={styles.subNav}>
            <div className={styles.breadCrumbs}>
              {breadCrumbs.map((crumb) => crumb)}
            </div>
            <div className={styles.actionItems}>
              <Textbox
                placeholder="Search Maps"
                handleChange={(values) => this.filterMaps(values)}
              />
              <Button
                color="orange"
                text="New Map"
                showImage
                image={require('../../assets/images/mapIconWhite.png')}
                handleClick={() => this.setState({ openNewMapModal: true })}
              />
              <Button
                color="gray-gradient"
                showImage
                image={require('../../assets/images/newFolder.png')}
                handleClick={() =>
                  this.setState({
                    openCreateFolderModal: true,
                    folderColor: '#fff'
                  })
                }
                noPadding
                disabled={activeFolder || isFreeUser}
              />
              <SortDropdown handleChange={(option) => this.sortMaps(option)} />
            </div>
          </header>
          <div className={styles.mainContent}>
            <div>
              {maps.map((map, index) => (
                <MapCard
                  key={index}
                  map={map}
                  handleClick={() => {
                    setActiveMap(map);
                    history.push('/cardMapView');
                  }}
                  handleMoveFolder={() =>
                    this.setState({ openMoveToFolderModal: true, map })
                  }
                  editMap={() =>
                    this.setState({ map, openEditMapNameModal: true })
                  }
                  deleteMap={() =>
                    this.setState({ map, openDeleteMapModal: true })
                  }
                  dupicateMap={() => addMap(map)}
                  handleShare={() =>
                    this.setState({ map, openShareModal: true })
                  }
                />
              ))}
            </div>
            <div>
              {folders.map((folder, index) => (
                <FolderCard
                  folder={folder}
                  key={index}
                  index={index}
                  onClick={() =>
                    setActiveFolder(folder) &&
                    fetchMaps({ folderId: folder._id })
                  }
                  changeColor={() =>
                    this.setState({
                      folderColor: folder.color,
                      folder: folder,
                      openChangeFolderColorModal: true
                    })
                  }
                  renameFolder={() =>
                    this.setState({ folder, openRenameFolderModal: true })
                  }
                  deleteFolder={() =>
                    this.setState({ folder, openDeleteFolderModal: true })
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <CreateFolderModal
          isOpen={openCreateFolderModal}
          closeActions={() => this.setState({ openCreateFolderModal: false })}
          folderColor={folderColor}
          openColorPicker={() => this.setState({ openColorPicker: true })}
          submitActions={(values) =>
            addFolder({ ...values, color: folderColor }) &&
            this.setState({ openCreateFolderModal: false, folderColor: '' })
          }
        />
        <ColorPickerModal
          isOpen={openColorPicker}
          closeActions={() => this.setState({ openColorPicker: false })}
          color={folderColor}
          handleChange={(color) => this.setState({ folderColor: color })}
        />
        <ChangeFolderColorModal
          isOpen={openChangeFolderColorModal}
          closeActions={() =>
            this.setState({ openChangeFolderColorModal: false })
          }
          submitActions={() =>
            updateFolder({ ...folder, color: folderColor }) &&
            this.setState({
              openChangeFolderColorModal: false,
              folderColor: '',
              folder: {}
            })
          }
          folderColor={folderColor}
          openColorPicker={() => this.setState({ openColorPicker: true })}
        />
        <RenameFolderModal
          isOpen={openRenameFolderModal}
          closeActions={() => this.setState({ openRenameFolderModal: false })}
          submitActions={(values) =>
            updateFolder({ ...this.state.folder, name: values.name }) &&
            this.setState({ openRenameFolderModal: false, folder: {} })
          }
          folderColor={folderColor}
        />
        <NewMapModal
          isOpen={openNewMapModal}
          closeActions={() => this.setState({ openNewMapModal: false })}
          submitActions={(values) =>
            addMap(values) && this.setState({ openNewMapModal: false })
          }
        />
        <MoveToFolderModal
          isOpen={openMoveToFolderModal}
          closeActions={() => this.setState({ openMoveToFolderModal: false })}
          folders={folders}
          handleChange={(values) => this.setState(values)}
          submitActions={() =>
            updateMap({ ...this.state.map, folderId: this.state.folderId }) &&
            this.setState({ openMoveToFolderModal: false, map: {} })
          }
        />
        <EditMapModal
          isOpen={openEditMapNameModal}
          closeActions={() => this.setState({ openEditMapNameModal: false })}
          submitActions={(values) =>
            updateMap({ ...this.state.map, name: values.name }) &&
            this.setState({ openEditMapNameModal: false, map: {} })
          }
          oldName={this.state.map.name}
        />
        <DeleteMapModal
          isOpen={openDeleteMapModal}
          closeActions={() => this.setState({ openDeleteMapModal: false })}
          submitActions={() =>
            deleteMap(this.state.map) &&
            this.setState({ openDeleteMapModal: false, map: {} })
          }
          mapname={this.state.map.name}
        />
        <DeleteFolderModal
          isOpen={openDeleteFolderModal}
          closeActions={() => this.setState({ openDeleteFolderModal: false })}
          submitActions={() =>
            deleteFolder(this.state.folder) &&
            this.setState({ openDeleteFolderModal: false, folder: {} })
          }
          mapname={this.state.folder.name}
        />
        <EmailSalesCoachModal
          isOpen={openEmailSalesCoachModal}
          closeActions={() =>
            this.setState({ openEmailSalesCoachModal: false })
          }
          submitActions={(values) => {
            emailSalesCoach(values);
            this.setState({ openEmailSalesCoachModal: false });
          }}
        />
        <Get30QuestionsModal
          isOpen={open30QuestionsModal}
          closeActions={() => this.setState({ open30QuestionsModal: false })}
        />
        <ShareModal
          isOpen={openShareModal}
          handleCopyTextToClipboard={(values) =>
            this.handleCopyTextToClipboard(values)
          }
          activeMap={map}
          closeActions={() => this.setState({ openShareModal: false })}
          isFromDashboard
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  maps: getMapListSelector(state),
  folders: getFolderListSelector(state),
  message: getMessagesSelector(state),
  activeFolder: getActiveFolderSelector(state),
  isFreeUser: isFreeUserSelector(state),
  activeUser: getActiveUserSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  addMap: (payload) => dispatch(addMapRequest(payload)),
  updateMap: (payload) => dispatch(updateMapRequest(payload)),
  deleteMap: (payload) => dispatch(deleteMapRequest(payload)),
  fetchMaps: (payload) => dispatch(fetchMapsRequest(payload)),
  setActiveMap: (payload) => dispatch(setActiveMap(payload)),
  addFolder: (payload) => dispatch(addFolderRequest(payload)),
  updateFolder: (payload) => dispatch(updateFolderRequest(payload)),
  deleteFolder: (payload) => dispatch(deleteFolderRequest(payload)),
  fetchFolders: (payload) => dispatch(fetchFoldersRequest(payload)),
  setActiveFolder: (payload) => dispatch(setActiveFolder(payload)),
  logout: (payload) => dispatch(logout(payload)),
  emailSalesCoach: (payload) => dispatch(emailSalesCoachRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
