import { createSelector } from 'reselect';

const userSelector = (state) => state.user;

export const isFreeUserSelector = createSelector(
  userSelector,
  (user) => user && user.isFreeUser
);

export const getActiveUserSelector = createSelector(
  userSelector,
  (user) => user && user.activeUser
);

export const isAuthenticated = createSelector(
  userSelector,
  (user) => user && user.authenticated
);
