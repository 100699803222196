import React from 'react';
import Button from '../inputs/Button';
import Modal from './index';

import styles from './styles.module.scss';

export default function ChangeFolderColorModal({
  isOpen,
  closeActions,
  submitActions,
  folderColor,
  openColorPicker
}) {
  return (
    <>
      <Modal
        title="Change Color"
        isOpen={isOpen}
        text="SAVE"
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          <div className={styles.row}>
            <Button
              color="orange"
              text="Pick Folder Color"
              showImage
              image={require('../../assets/images/colorPickerWhite.png')}
              handleClick={openColorPicker}
            />
            <div
              className={styles.folder}
              style={{ backgroundColor: folderColor || 'white' }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
