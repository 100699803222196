import React from 'react';

import styles from './styles.module.scss';

export default function Textbox({
  label,
  placeholder,
  type,
  fullWidth,
  disabled,
  currentValue,
  handleChange,
  name,
  required
}) {
  const classes = fullWidth
    ? [styles.textbox, styles.fullWidth]
    : [styles.textbox];
  return (
    <>
      <div className={classes.join(' ')}>
        {label && (
          <label htmlFor={name || 'textbox'}>
            {required && (
              <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
            )}
            {label}
          </label>
        )}
        <input
          value={currentValue}
          placeholder={placeholder || 'Type here...'}
          id={name || 'textbox'}
          type={type}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </>
  );
}
