import React from 'react';
import Modal from './index';

export default function DeleteMapModal({
  isOpen,
  closeActions,
  submitActions,
  mapname
}) {
  return (
    <>
      <Modal
        title="Delete Map"
        isOpen={isOpen}
        text="DELETE"
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          Are you sure you want to delete {mapname}? This action is permanent.
          You will be able to create a similar map again.
        </div>
      </Modal>
    </>
  );
}
