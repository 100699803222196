import {
  ADD_FOLDER_REQUEST,
  ADD_FOLDER_RESPONSE,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_RESPONSE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_RESPONSE,
  FETCH_FOLDERS_REQUEST,
  FETCH_FOLDERS_RESPONSE,
  SET_ACTIVE_FOLDER
} from '../constants/actionTypes';

const initialState = { loading: false, list: [] };

const folders = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FOLDERS_REQUEST:
    case ADD_FOLDER_REQUEST:
    case UPDATE_FOLDER_REQUEST:
    case DELETE_FOLDER_REQUEST:
      return { ...state, loading: true };

    case FETCH_FOLDERS_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };

    case ADD_FOLDER_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };

    case UPDATE_FOLDER_RESPONSE: {
      let mapList = [...state.list];

      return {
        ...state,
        loading: false,
        list: mapList.map((map) => {
          if (map._id === payload._id) {
            return {
              ...payload
            };
          }
          return map;
        })
      };
    }

    case DELETE_FOLDER_RESPONSE: {
      let folderList = state.list;

      folderList = folderList.filter((map) => map._id !== payload._id);

      return {
        ...state,
        loading: false,
        list: folderList
      };
    }

    case SET_ACTIVE_FOLDER: {
      return {
        ...state,
        loading: false,
        activeFolder: payload
      };
    }

    default:
      return { ...state };
  }
};

export default folders;
