import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import history from '../../history';

import styles from './styles.module.scss';

export default class DashboardHeader extends Component {
  render() {
    const {
      logout,
      isFreeUser,
      openEmailCoachModal,
      username,
      openQuestionsModal
    } = this.props;

    return (
      <header className={styles.dashboardNav}>
        <div className={styles.leftSide}>
          <div
            onClick={() => history.push('/dashboard')}
            className={styles.dashboardButton}
          >
            INFLUENCE MAPPER
          </div>
          <div>
            <div
              onClick={() => history.push('/dashboard')}
              className={styles.dashboardButton}
            >
              Dashboard
            </div>
            <div>|</div>
            <div data-tip="support" data-for="support">
              Learn & Support
            </div>
            <ReactTooltip
              className={styles.customTheme}
              place="bottom"
              effect="solid"
              type="light"
              id="support"
              delayHide={500}
            >
              <ul className={styles.dataList}>
                <li className={styles.disabled}>Getting Started</li>
                <li className={styles.disabled}>Selling Concepts</li>
                <li onClick={openQuestionsModal}>30 Questions to Help</li>
                <li onClick={openEmailCoachModal}>Contact a Sales Coach</li>
              </ul>
            </ReactTooltip>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div data-tip="account" data-for="account">
            {isFreeUser ? 'Account' : username}
          </div>
          <ReactTooltip
            className={styles.customTheme}
            place="bottom"
            effect="solid"
            type="light"
            id="account"
            delayHide={500}
          >
            {isFreeUser ? (
              <ul className={styles.dataList}>
                <li onClick={logout}>
                  <img
                    src={require('../../assets/images/SignIn.png')}
                    alt="Sign In"
                  />{' '}
                  <div>Sign In</div>
                </li>
              </ul>
            ) : (
              <ul className={styles.dataList}>
                <li onClick={() => history.push('/settings')}>
                  <img
                    src={require('../../assets/images/Settings.png')}
                    alt="Settings"
                  />{' '}
                  <div>Settings</div>
                </li>
                <li onClick={logout}>
                  <img
                    src={require('../../assets/images/SignIn.png')}
                    alt="Sign Out"
                  />{' '}
                  <div>Sign Out</div>
                </li>
              </ul>
            )}
          </ReactTooltip>
        </div>
      </header>
    );
  }
}
