import React, { Component } from 'react';

import styles from './styles.module.scss';

export default class FolderCard extends Component {
  state = {
    color: '#fff'
  };

  componentDidMount() {
    if (this.props.folder.color) {
      this.setState({ color: this.props.folder.color });
      const style = document.getElementById(`folder_${this.props.index}`).style;
      style.setProperty('--background', this.props.folder.color);
    } else {
      const style = document.getElementById(`folder_${this.props.index}`).style;
      style.setProperty('--background', '#fff');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.folder.color !== this.props.folder.color) {
      this.setState({ color: this.props.folder.color });
      const style = document.getElementById(`folder_${this.props.index}`).style;
      style.setProperty('--background', this.props.folder.color);
    }
  }

  render() {
    const {
      folder,
      onClick,
      changeColor,
      renameFolder,
      deleteFolder,
      index
    } = this.props;
    const { color } = this.state;

    return (
      <>
        <div className={styles.folderCard} id={`folder_${index}`}>
          <div
            className={styles.folder}
            style={{ backgroundColor: color }}
            onClick={onClick}
          >
            <img alt="Open Folder" />
          </div>
          <div className={styles.footer}>
            <div>{folder.name}</div>
            <div className={styles.menu}>
              <div>
                <div className={styles.circles} />
                <div className={styles.circles} />
                <div className={styles.circles} />
              </div>
              <ul>
                <li onClick={deleteFolder}>
                  <img alt="Delete Folder" />
                  Delete
                </li>
                <li onClick={changeColor}>
                  <img alt="Change Folder Color" />
                  Change Color
                </li>
                <li onClick={renameFolder}>
                  <img alt="Rename Folder" />
                  Rename
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
