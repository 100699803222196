import React, { Component } from 'react';
import { SwatchesPicker } from 'react-color';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default class ColorPickerModal extends Component {
  handleChange(color) {
    this.props.handleChange(color.hex);
  }

  handleChangeComplete(color) {
    this.props.handleChange(color.hex);
  }

  render() {
    const { isOpen, closeActions, color } = this.props;

    const className = isOpen
      ? [styles.modal, styles.modalShow, styles.colorPickerModal]
      : [styles.modal, styles.modalHide];

    return (
      <>
        <div className={className.join(' ')}>
          <div className={styles.modalBody}>
            <SwatchesPicker
              onChangeComplete={this.handleChangeComplete.bind(this)}
              color={color}
              width={450}
              height={475}
            />
            <Button
              color="orange"
              text="Save Color"
              handleClick={closeActions}
            />
          </div>
        </div>
      </>
    );
  }
}
