import {
  UNKNOWN,
  WIZARD,
  ULTIMATE_DM,
  EXECUTIVE,
  FINANCIAL,
  TECHNICAL,
  ADMINISTRATIVE,
  LINE_OF_BUSINESS
} from '../constants/constants';
import { influenceMapper } from './userCardHelper';

export const evaluate = (userCards) => {
  const infoMessages = [];
  const issueMessages = [];
  const warningMessages = [];
  const criticalMessages = [];

  const UDM = filterCardsByRole(userCards, ULTIMATE_DM);

  if (UDM.length === 0) {
    warningMessages.push("You haven't indentified the Ultimate Decision Maker");
  } else if (UDM.length > 1) {
    infoMessages.push(
      `There is more than person designated as the UDM: ${UDM.map(
        (card) => card.name
      ).join(', ')}. There is normally only one.`
    );
  }

  UDM.forEach((card) => {
    if (parseInt(card.buyerPower, 10) < 3) {
      issueMessages.push(
        `The UDM, ${card.name}, should not have a buyer power level of less than +3`
      );
    }
  });

  const wizards = filterCardsByRole(userCards, WIZARD);

  if (wizards.length === 0) {
    warningMessages.push("You haven't identified the Wizard.");
  } else if (wizards.length > 1) {
    infoMessages.push(
      `There is more than person designated as the Wizard: ${wizards
        .map((card) => card.name)
        .join(', ')}. There is normally only one.`
    );
  }

  wizards.forEach((card) => {
    if (parseInt(card.buyerPower, 10) < 3) {
      issueMessages.push(
        `The Wizard, ${card.name}, should not have a buyer power level of less than +3`
      );
    }
  });

  const unknown = filterCardsByRole(userCards, UNKNOWN);

  unknown.forEach((card) => {
    warningMessages.push(
      `${card.name}'s role is unknown. Role needs to be determined.`
    );
  });

  addMessagesOfTypes(
    filterCardsByType(userCards, EXECUTIVE),
    'EXECUTIVE',
    warningMessages
  );
  addMessagesOfTypes(
    filterCardsByType(userCards, FINANCIAL),
    'FINANCIAL',
    warningMessages
  );
  addMessagesOfTypes(
    filterCardsByType(userCards, TECHNICAL),
    'TECHNICAL',
    warningMessages
  );
  addMessagesOfTypes(
    filterCardsByType(userCards, ADMINISTRATIVE),
    'ADMINISTRATIVE',
    warningMessages
  );
  addMessagesOfTypes(
    filterCardsByType(userCards, LINE_OF_BUSINESS),
    'LINE OF BUSINESS',
    warningMessages
  );

  const powerCards = [...UDM, ...wizards];

  powerCards.forEach((card) => {
    if (influenceMapper(card.buyerPower, card.friendFoe).text === 'negative') {
      criticalMessages.push(
        `You are in an unfavorable position with ${card.name} a primary decision maker and/or influencer`
      );
    }
  });

  return {
    issueMessages,
    infoMessages,
    warningMessages,
    criticalMessages
  };
};

const filterCardsByRole = (cards, role) => {
  return cards.filter((card) => card.roles.includes(role));
};

const filterCardsByType = (cards, type) => {
  return cards.filter((card) => card.types.includes(type));
};

const addMessagesOfTypes = (cards, type, warningMessages) => {
  if (cards.length === 0) {
    warningMessages.push(`There isn't a ${type} Buyer identified`);
  } else if (!getPositiveNumberOfCards(cards)) {
    warningMessages.push(
      `There isn't a ${type} who is positive or "green" (i.e. is your advocate and has significant influence)`
    );
  }
};

const getPositiveNumberOfCards = (cards) => {
  let numberOfPositivePeople = 0;

  cards.forEach((card) => {
    if (influenceMapper(card.buyerPower, card.friendFoe).text === 'positive') {
      numberOfPositivePeople++;
    }
  });

  return numberOfPositivePeople;
};
