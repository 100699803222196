import React from 'react';
import Modal from './index';

export default function DeleteUserModal({
  isOpen,
  closeActions,
  submitActions,
  username
}) {
  return (
    <>
      <Modal
        title="Delete User"
        isOpen={isOpen}
        text="DELETE"
        closeActions={closeActions}
        submitActions={submitActions}
      >
        <div>
          Are you sure you want to delete {username}? This action is permanent.
          You can sign up for a new account but you will lose all your data.
        </div>
      </Modal>
    </>
  );
}
