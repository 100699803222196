import axios from 'axios';
import config from '../config/config';
import history from '../history';

function setTokenHeader() {
  return localStorage.getItem('token');
}

axios.default.withCredentials = true;

const topGunApi = axios.create({
  baseURL: `${config.apiUrl}`
});

topGunApi.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = setTokenHeader();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

topGunApi.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 500 || error.response.status === 418) {
      if (process.env.NODE_ENV === 'development') throw error.response.data;
      else history.push('/error');
    }

    if (error.response.status === 403) {
      localStorage.removeItem('token');
      history.push('/login');
    }

    throw error.response.data;
  }
);

export const login = (payload) =>
  topGunApi
    .post(`/userAccount/login`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const googleLogin = (payload) =>
  topGunApi
    .post(`/userAccount/googleLogin`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const sendCoachEmail = (payload) =>
  topGunApi
    .post(`/userAccount/emailSalesCoach`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const forgotPassword = (payload) =>
  topGunApi
    .post(`/userAccount/forgotPassword`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const createNewUser = (payload) =>
  topGunApi
    .post(`/userAccount`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateUser = (payload) =>
  topGunApi
    .put(`/userAccount/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchMaps = (payload) =>
  topGunApi
    .get(`/maps/?accountId=${payload.userId}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchMapsByFolder = (payload) =>
  topGunApi
    .get(`/maps/?accountId=${payload.userId}&folderId=${payload.folderId}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchMapsById = (payload) =>
  topGunApi
    .get(`/maps/${payload._id}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const addMap = (payload) =>
  topGunApi
    .post(`/maps`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateMap = (payload) =>
  topGunApi
    .put(`/maps/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const deleteMap = (payload) =>
  topGunApi
    .delete(`/maps/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchUserCards = (payload) =>
  topGunApi
    .get(`/userCards/?mapId=${payload.mapId}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const addUserCard = (payload) =>
  topGunApi
    .post(`/userCards`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateUserCard = (payload) =>
  topGunApi
    .put(`/userCards/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const deleteUserCard = (payload) =>
  topGunApi
    .delete(`/userCards/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchFolders = (payload) =>
  topGunApi
    .get(`/folders/?accountId=${payload.accountId}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const addFolder = (payload) =>
  topGunApi
    .post(`/folders`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateFolder = (payload) =>
  topGunApi
    .put(`/folders/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const deleteFolder = (payload) =>
  topGunApi
    .delete(`/folders/${payload._id}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchLines = (payload) =>
  topGunApi
    .get(`/lines/?mapId=${payload.mapId}`)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const addLine = (payload) =>
  topGunApi
    .post(`/lines`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const deleteLine = (payload) =>
  topGunApi
    .delete(`/lines/${payload}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
