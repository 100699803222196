import { call, put, select, all } from 'redux-saga/effects';
import history from '../history';
import { addMessage, removeMessage } from '../actions/messages';
import { addUserResponse } from '../actions/user';
import { getActiveMapSelector } from '../selectors/maps';
import { getUserCardListSelector } from '../selectors/userCards';
import { addMapResponse, setActiveMap } from '../actions/maps';
import { fetchUserCardsResponse } from '../actions/userCards';

export function* freeUser() {
  try {
    yield put(removeMessage());
    localStorage.removeItem('token');
    history.push('/freeDashboard');
  } catch (error) {
    console.log(error);
  }
}

export function* logInUser(api, { payload }) {
  try {
    yield put(removeMessage());

    localStorage.removeItem('token');
    const referrer = payload.referrer;
    delete payload.referrer;

    const response = yield call(api.login, payload);

    localStorage.setItem('token', response.token);

    yield put(addUserResponse(response._doc));

    const referrerUrl = referrer ? referrer.split('/') : [];

    if (referrerUrl.includes('cardMapView') && referrerUrl[2]) {
      history.push(`/cardMapView/${referrerUrl[2]}`);
    } else {
      history.push('/dashboard');
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* googleLogInUser(api, { payload }) {
  try {
    yield put(removeMessage());

    localStorage.removeItem('token');
    const referrer = payload.referrer;
    delete payload.referrer;

    const response = yield call(api.googleLogin, { token: payload.tokenId });

    localStorage.setItem('token', response.token);

    yield put(addUserResponse(response._doc));

    const referrerUrl = referrer ? referrer.split('/') : [];

    if (referrerUrl.includes('cardMapView') && referrerUrl[2]) {
      history.push(`/cardMapView/${referrerUrl[2]}`);
    } else {
      history.push('/dashboard');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* newUserRegistration(api, { payload }) {
  try {
    localStorage.removeItem('token');
    const referrer = payload.referrer;
    delete payload.referrer;

    const response = yield call(api.createNewUser, payload);

    localStorage.setItem('token', response.token);

    yield put(addUserResponse(response._doc));

    const referrerUrl = referrer ? referrer.split('/') : [];

    if (referrerUrl.includes('cardMapView')) {
      history.push(`/cardMapView/${referrerUrl[2]}`);
    } else {
      history.push('/dashboard');
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* updateUser(api, { payload }) {
  try {
    const response = yield call(api.updateUser, payload);

    yield put(addUserResponse(response));
    history.push('/dashboard');
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* sendCoachEmail(api, { payload }) {
  try {
    yield put(removeMessage());

    yield call(api.sendCoachEmail, payload);

    yield put(
      addMessage({ error: false, message: 'Email Sent Successfully!' })
    );
  } catch (error) {
    console.error(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* forgotPassword(api, { payload }) {
  try {
    yield put(removeMessage());

    yield call(api.forgotPassword, payload);

    yield put(
      addMessage({ error: false, message: 'Email Sent Successfully!' })
    );
  } catch (error) {
    console.error(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* addUserAndMap(api, { payload }) {
  try {
    const activeMap = yield select(getActiveMapSelector);
    const userCards = yield select(getUserCardListSelector);

    const response = payload.tokenId
      ? yield call(api.googleLogin, { token: payload.tokenId })
      : yield call(api.createNewUser, payload);

    localStorage.setItem('token', response.token);
    yield put(addUserResponse({ ...response._doc, isFreeUser: false }));

    delete activeMap._id;
    const mapResponse = yield call(api.addMap, {
      ...activeMap,
      accountId: response._doc._id
    });
    yield put(addMapResponse(mapResponse));
    yield put(setActiveMap(mapResponse));

    const userCardsResponses = yield all(
      userCards.map((userCard) => {
        delete userCard._id;
        return call(api.addUserCard, {
          ...userCard,
          mapId: mapResponse._id,
          accountId: response._doc._id
        });
      })
    );
    yield put(fetchUserCardsResponse(userCardsResponses));
    history.push(`/cardMapView`);
  } catch (error) {
    console.error(error);
    yield put(addMessage({ error: true, message: error }));
  }
}
