export const FOE_FRIEND = [
  {
    value: -4,
    label: '-4',
    tooltipName: 'hostile',
    tooltip: 'Openly hostile or negative.  Clearly in the competitors camp'
  },
  {
    value: -3,
    label: '-3',
    tooltipName: 'lessHostile',
    tooltip: 'Clearly not in favor of your solution'
  },
  {
    value: -2,
    label: '-2',
    tooltipName: 'lesserHostile',
    tooltip: 'Leaning away from your solution or towards your competitors'
  },
  {
    value: -1,
    label: '-1',
    tooltipName: 'leastHostile',
    tooltip: 'Slight preference towards a competitive offering'
  },
  {
    value: 0,
    label: '0',
    tooltipName: 'noPreference',
    tooltip: 'No preference'
  },
  {
    value: 1,
    label: '1',
    tooltipName: 'leastFan',
    tooltip: 'Slight preference towards your solution'
  },
  {
    value: 2,
    label: '2',
    tooltipName: 'lesserFan',
    tooltip: 'Leaning towards your solution'
  },
  {
    value: 3,
    label: '3',
    tooltipName: 'lessFan',
    tooltip: 'Strong preference towards your solution'
  },
  {
    value: 4,
    label: '4',
    tooltipName: 'fan',
    tooltip: 'Raving fan.  Openly supportive of you'
  }
];

export const BUYER_POWER = [
  {
    value: -1,
    label: '?',
    tooltipName: 'risk',
    tooltip:
      'This is a risk.  Take action to determine their level of influence.'
  },
  {
    value: 0,
    label: '0',
    tooltipName: 'noPower',
    tooltip: 'No influence or power.  Don’t waste a lot of time here'
  },
  {
    value: 1,
    label: '1',
    tooltipName: 'slightPower',
    tooltip: 'Slight influence'
  },
  {
    value: 2,
    label: '2',
    tooltipName: 'influence',
    tooltip:
      'Most evaluators and influencers fall into this value.  Monitor their opinions and voice'
  },
  {
    value: 3,
    label: '3',
    tooltipName: 'strongInfluence',
    tooltip:
      'Strong influence.  They can stop or speed-up the process.  They are a major player.  Budget holders and key buyers are often here'
  },
  {
    value: 4,
    label: '4',
    tooltipName: 'boss',
    tooltip:
      'This person can make it happen.   UDMs and Wizards are common here'
  }
];

export const UNKNOWN = 0;
export const NOT_INVOLVED = 1;
export const GATEKEEPER = 2;
export const END_USER = 3;
export const INFLUENCER = 4;
export const EVALUATOR = 5;
export const COACH = 6;
export const SPY = 7;
export const OWNS_BUDGET = 8;
export const WIZARD = 9;
export const ULTIMATE_DM = 10;

export const EXECUTIVE = 0;
export const FINANCIAL = 1;
export const TECHNICAL = 2;
export const ADMINISTRATIVE = 3;
export const LINE_OF_BUSINESS = 4;

export const SORTDROPDOWN_OPTIONS = [
  {
    text: 'Date Created',
    image: require('../assets/images/createdByDesc.png')
  },
  {
    text: 'Alphabetical',
    image: require('../assets/images/azAsc.png')
  },
  {
    text: 'Last Modified',
    image: require('../assets/images/lastModified.png')
  }
];
