import { call, select, put, all } from 'redux-saga/effects';
import { setActiveMap, fetchMapsResponse } from '../actions/maps';
import { fetchLinesResponse } from '../actions/lines';
import { addMessage, removeMessage } from '../actions/messages';
import {
  fetchUserCardsResponse,
  addUserCardResponse,
  updateUserCardResponse,
  deleteUserCardResponse
} from '../actions/userCards';
import { exampleUserCard } from '../constants/example';
import { getActiveMapSelector } from '../selectors/maps';
import { isFreeUserSelector, getActiveUserSelector } from '../selectors/user';
import { getUserCardListSelector } from '../selectors/userCards';

export function* fetchUserCards(api) {
  try {
    const isFreeUser = yield select(isFreeUserSelector);
    const activeMap = yield select(getActiveMapSelector);

    let userCardList = [];

    if (isFreeUser && activeMap._id !== 2) {
      userCardList = [exampleUserCard];
    } else if (!isFreeUser) {
      userCardList = yield call(api.fetchUserCards, { mapId: activeMap._id });
      const response = yield call(api.fetchLines, {
        accountId: activeMap.accountId,
        mapId: activeMap._id
      });

      yield put(fetchLinesResponse(response));
    }

    yield put(fetchUserCardsResponse(userCardList));
  } catch (error) {
    console.log(error);
  }
}

export function* fetchMapsAndUserCards(api, { payload }) {
  try {
    const activeMap = yield call(api.fetchMapsById, payload);

    const userCardList = yield call(api.fetchUserCards, {
      mapId: activeMap._id
    });

    const linesList = yield call(api.fetchLines, {
      mapId: activeMap._id
    });

    yield put(setActiveMap(activeMap));
    yield put(fetchMapsResponse([activeMap]));
    yield put(fetchUserCardsResponse(userCardList));
    yield put(fetchLinesResponse(linesList));
  } catch (error) {
    console.log(error);
  }
}

export function* addUserCard(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeMap = yield select(getActiveMapSelector);
    const activeUser = yield select(getActiveUserSelector);
    const isFreeUser = yield select(isFreeUserSelector);
    const userList = yield select(getUserCardListSelector);

    delete payload._id;

    if (isFreeUser) {
      yield put(
        addUserCardResponse({
          ...payload,
          _id: userList.length + 1,
          controlledPosition: { x: 500, y: 0 }
        })
      );
    } else {
      const response = yield call(api.addUserCard, {
        ...payload,
        mapId: activeMap._id,
        accountId: activeUser._id,
        controlledPosition: { x: 500, y: 10 }
      });
      yield put(addUserCardResponse(response));
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* updateUserCard(api, { payload }) {
  try {
    yield put(removeMessage());
    const isFreeUser = yield select(isFreeUserSelector);
    if (isFreeUser) {
      yield put(updateUserCardResponse(payload));
    } else {
      const response = yield call(api.updateUserCard, payload);
      yield put(updateUserCardResponse(response));
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* deleteUserCard(api, { payload }) {
  try {
    yield put(removeMessage());
    const isFreeUser = yield select(isFreeUserSelector);

    if (isFreeUser) {
      yield put(deleteUserCardResponse(payload));
    } else {
      yield call(api.deleteUserCard, payload);
      yield put(deleteUserCardResponse(payload));
    }
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* saveAllUserCards(api, { payload }) {
  try {
    yield put(removeMessage());

    yield all(payload.map((userCard) => call(api.updateUserCard, userCard)));

    yield put(addMessage({ error: false, message: 'Saved Map Successfully' }));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}
